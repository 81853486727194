<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux" ref="testDiv">
    <Sitebar></Sitebar>
    <div class="maincontainer" style="padding-bottom:4em; padding-right: 50px;">
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row create-dashboard-top mb-2">
        <div class="card p-2">
          <div class="row p-0 m-0">
            <p for="" class="p-0 m-0 mb-1" v-if="investigationRequiredValues"><small style="color:red">{{
              $t('requiredValue')
            }}</small></p>
            <!--<Button class="custom-btn col-1 mr-2" :icon="(!toggleNewNode) ? 'pi pi-plus' : 'pi pi-minus'"
              style="width:2em !important; height:2em !important" @click="toggleNewNode = !toggleNewNode; lowOpacity = false;" />-->
            <InputText class="col-3 mr-2" :placeholder="'*' + $t('investigationName')" v-model="investigation_name">
            </InputText>
            <InputText class="col-6 mr-2" :placeholder="$t('investigationDescription')" v-model="investigation_desc">
            </InputText>
            <Button :label="$t('save')" class="custom-btn col-2" icon="pi pi-save"
              @click="(investigation_name) ? saveInvestigation(investigation_name, investigation_desc) : dashboardRequiredValues = true"
              style="" />
            <p class="p-0 ml-4 col-1" v-if="!is_saved" style="float:right;color:red">* {{ $t('notSaved') }}</p>
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Canvas ===================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" style="width: -moz-available;">
        <div class="col-12 pt-0 pb-0 pl-0 pr-0">
          <div class="dashboard-canvas" style="min-height:72vh;">
            <Button class="custom-btn col-1 mr-2" :icon="(!toggleNewNode) ? 'pi pi-plus' : 'pi pi-minus'"
              style="width:2em !important; height:2em !important"
              @click="toggleNewNode = !toggleNewNode; lowOpacity = false;" />
            <VueFlow :class="'col-12 p-0 m-0 custom-vflow'" v-model="flow_chart" :zoom-on-scroll="true"
              :zoom-on-pinch="false" :zoom-on-double-click="false" :pan-on-scroll-speed="0" :pan-on-drag="true"
              :prevent-scrolling="true" :default-zoom="defaultZoom" :max-zoom="4" :min-zoom="0.1"
              connection-mode="loose" @node-click="onNodeClick($event)" @node-drag-start="onNodeDragStart($event)"
              @node-drag="onNodeDrag($event)" @node-drag-stop="onNodeDragStop($event)"
              @node-double-click="onNodeDoubleClick($event)" @edge-update="onEdgeUpdate({ edge, connection })"
              @connect="onConnect(); onConnectCustom()" @edge-double-click="onEdgeDoubleClick($event)"
              @edge-click="onEdgeClick($event)" @pane-click="isNodeDetails = false; isEdgeOptions = false">
              <Controls :fitViewParams="{ maxZoom: 2, offset: { x: -300, y: 0 } }" />
              <MiniMap pannable zoomable height="100" width="100" />
              <!--
              @node-mouse-enter="onNodeMouseEnter($event)"
              @node-mouse-leave="onNodeMouseLeave($event)"
              @node-mouse-move="onNodeMouseMove($event)"
              -->

              <!-- Default node -->
              <template #node-customdefault="props">
                <div
                  :class="(props.data.selected) ? 'card incident-node selected-node node-default-style' : 'card incident-node node-default-style'"
                  :style="'color: ' + props.data.icon_color + ';'" v-tooltip.top="props.label">
                  <div class="warning-node-div" v-if="props.data.warning">
                    <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                  </div>
                  <div class="card-body text-center pt-0 pb-1 pl-1 pr-1" v-if="props.data.type != 'user'">
                    <i :class="'fa pi fa-lg ' + props.data.icon"></i>
                  </div>
                  <div class="node-picture" v-else>
                    <Avatar v-if="props.data.object.picture == null"
                      :label="props.data.object.full_name[0].toUpperCase()" shape="circle" size="" />
                    <img v-else :src="getSourceLogo(props.data.object.picture)" class="rounded-circle" height="25"
                      width="25" />
                  </div>
                </div>
                <Handle type="target" :position="Position.Top" :id="'top-handle'" v-if="props.data.handles[0]" />
                <Handle type="target" :position="Position.Right" :id="'right-handle'" v-if="props.data.handles[1]" />
                <Handle type="target" :position="Position.Bottom" :id="'bottom-handle'" v-if="props.data.handles[2]" />
                <Handle type="target" :position="Position.Left" :id="'left-handle'" v-if="props.data.handles[3]" />
                <div class="my-node-node-title text-center">{{ formatString(props.label, 18) }}</div>
              </template>

              <!-- Incident node -->
              <template #node-incident="props">
                <div
                  :class="(props.data.selected) ? 'card incident-node selected-node node-default-style' : 'card incident-node node-default-style'"
                  :style="'color: ' + props.data.icon_color + ';'" v-tooltip.top="props.label">
                  <div class="warning-node-div" v-if="props.data.warning">
                    <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                  </div>
                  <div class="card-body text-center pt-0 pb-1 pl-1 pr-1">
                    <i class="fa pi-briefcase pi fa-lg"></i>
                  </div>
                </div>
                <Handle type="target" :position="Position.Top" :id="'top-handle'" v-if="props.data.handles[0]" />
                <Handle type="target" :position="Position.Right" :id="'right-handle'" v-if="props.data.handles[1]" />
                <Handle type="target" :position="Position.Bottom" :id="'bottom-handle'" v-if="props.data.handles[2]" />
                <Handle type="target" :position="Position.Left" :id="'left-handle'" v-if="props.data.handles[3]" />
                <div class="my-node-node-title text-center">{{ formatString(props.label, 18) }}</div>
              </template>

              <!-- Event node -->
              <template #node-event="props">
                <div
                  :class="(props.data.selected) ? 'card event-node selected-node node-default-style' : 'card event-node node-default-style'"
                  :style="'color: ' + props.data.icon_color + ';'" v-tooltip.top="props.label">
                  <div class="card-body text-center pt-0 pb-1 pl-1 pr-1">
                    <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                  </div>
                  <div class="warning-node-div" v-if="props.data.warning">
                    <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                  </div>
                </div>
                <Handle type="target" :position="Position.Top" :id="'top-handle'" v-if="props.data.handles[0]" />
                <Handle type="target" :position="Position.Right" :id="'right-handle'" v-if="props.data.handles[1]" />
                <Handle type="target" :position="Position.Bottom" :id="'bottom-handle'" v-if="props.data.handles[2]" />
                <Handle type="target" :position="Position.Left" :id="'left-handle'" v-if="props.data.handles[3]" />
                <div class="my-node-node-title text-center">{{ formatString(props.label, 18) }}</div>
              </template>

              <!-- None node -->
              <template #node-none="props">
                <div
                  :class="(props.data.selected) ? 'card none-node selected-node node-default-style' : 'card none-node node-default-style'"
                  :style="'color: ' + props.data.icon_color + ';'" v-tooltip.top="props.label">
                  <div class="card-body text-center pt-0 pb-1 pl-1 pr-1">
                    <i class="fa pi-box pi fa-lg"></i>
                  </div>
                  <div class="warning-node-div" v-if="props.data.warning">
                    <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                  </div>
                </div>
                <Handle type="target" :position="Position.Top" :id="'top-handle'" v-if="props.data.handles[0]" />
                <Handle type="target" :position="Position.Right" :id="'right-handle'" v-if="props.data.handles[1]" />
                <Handle type="target" :position="Position.Bottom" :id="'bottom-handle'" v-if="props.data.handles[2]" />
                <Handle type="target" :position="Position.Left" :id="'left-handle'" v-if="props.data.handles[3]" />
                <div class="my-node-node-title text-center">{{ formatString(props.label, 18) }}</div>
              </template>

            </VueFlow>
            <!-- ========================= New Node options ========================= -->
            <div
              :class="(lowOpacity) ? 'col-3 card ml-0 mt-2 low-opacity general-node-options' : 'col-3 card ml-0 mt-2 general-node-options'"
              style="position:absolute; z-index:3; top:0" v-if="toggleNewNode">

              <h5>{{ $t('newNodeOptions') }}</h5>
              <i class="pi pi-times mr-2 mt-2" @click="toggleNewNode = false;"
                style="position: absolute; top: 0; right: 0; cursor:pointer"></i>
              <p class="node-options-subtitle">{{ $t('nodeType') }}</p>
              <Dropdown v-model="newObjectNode.data.type" :options="nodeTypes" :placeholder="$t('nodeType')"
                @change="getSelectedNodeTypeObjects(newObjectNode.data.type)">
                <template #value="slotProps">
                  <div v-if="isInModelsOrSources(slotProps.value) === 'model'" class="flex align-items-center">
                    <img :src="db_logo" style="height: 1em; width: 1em; margin-top: -3px" /> {{ prettifyString(slotProps.value) }}
                  </div>
                  <span v-else>
                    <img :src="elastic_logo" style="height: 1em; width: 1em; margin-top: -3px" /> {{ prettifyString(slotProps.value) }}
                  </span>
                </template>

                <template #option="slotProps">
                  <div v-if="isInModelsOrSources(slotProps.option) === 'model'" class="flex align-items-center">
                    <img :src="db_logo" style="height: 1em; width: 1em; margin-top: -3px" /> {{ prettifyString(slotProps.option) }}
                  </div>
                  <span v-else>
                    <img :src="elastic_logo" style="height: 1em; width: 1em; margin-top: -3px" /> {{ prettifyString(slotProps.option) }}
                  </span>
                </template>

              </Dropdown>

              <section v-if="newObjectNode.data.type == 'none'">
                <p class="node-options-subtitle">{{ $t('name') }}</p>
                <InputText v-model="newObjectNode.label" class="form-control" placeholder="Node name" />
                <p class="node-options-subtitle">{{ $t('description') }}</p>
                <textarea v-model="newObjectNode.data.description" class="form-control" placeholder="Node description"
                  rows="3" />
                <div class="p-0 m-0 node-colorpicker">
                  <p class="node-options-subtitle">{{ $t('node') }} {{ $t('color') }}</p>
                  <ColorPicker class format="hex" v-model="newObjectNode.data.icon_color" />
                </div>
              </section>

              <p class="node-options-subtitle">{{ $t('handles') }}</p>
              <div class="node-options general-node-options m-0 p-0 flex flex-wrap justify-content-center gap-3">
                <Checkbox v-model="newObjectNode.data.handles[0]" inputId="Top" name="handles"
                  :value="newObjectNode.data.handles[0]" :binary="true" />
                <label for="Top" class="ml-2"> Top </label>
                <Checkbox v-model="newObjectNode.data.handles[1]" inputId="Right" name="handles"
                  :value="newObjectNode.data.handles[1]" :binary="true" />
                <label for="Right" class="ml-2"> Right </label>
                <Checkbox v-model="newObjectNode.data.handles[2]" inputId="Bottom" name="handles"
                  :value="newObjectNode.data.handles[2]" :binary="true" />
                <label for="Bottom" class="ml-2"> Bottom </label>
                <Checkbox v-model="newObjectNode.data.handles[3]" inputId="Left" name="handles"
                  :value="newObjectNode.data.handles[3]" :binary="true" />
                <label for="Left" class="ml-2"> Left </label>
              </div>

              <p class="node-options-subtitle">{{ $t('others') }}</p>
              <div class="node-options general-node-options m-0 p-0 flex flex-wrap justify-content-center gap-3">
                <Checkbox v-model="newObjectNode.data.warning" inputId="warning" name="handles"
                  :value="newObjectNode.data.warning" :binary="true" />
                <label for="warning" class="ml-2"> Add Warning </label>
                <textarea v-if="newObjectNode.data.warning" v-model="newObjectNode.data.warning_description"
                  class="form-control" placeholder="Warning description" rows="3"></textarea>
              </div>


              <section v-if="newObjectNode.data.type != 'none'">
                <p class="node-options-subtitle">{{ $t('select') }} {{ newObjectNode.data.type }}</p>
                <Dropdown v-model="newNodeSelectedObject" :options="nodeTypeModelOptions"
                  :optionLabel="(nodeTypeModelOptions[0].title) ? 'title' : ((nodeTypeModelOptions[0].name) ? 'name' : ((nodeTypeModelOptions[0].full_name) ? 'full_name' : 'index_rf'))"
                  :placeholder="$t('chooseObject')" />
              </section>


              <Button @click="newNode(newObjectNode.data.type)" class="btn-custom-cancel node-options-button mt-2"
                icon="pi pi-plus" :label="$t('create') + ' ' + $t('node')"></Button>
            </div>

            <div class="aux-canvas-div col-4 creator-aux-canvas-div" style="pointer-events:none;">
              <ScrollPanel style="height:65vh; overflow-y:auto;">
                <!-- ========================= Node details ========================= -->
                <Transition name="">
                  <div class="row" v-if="!isNodeDetails" @click="isNodeDetails = true; focusSelectedNode()">
                    <div class="col-11">
                    </div>
                    <div :class="'col-1 card side-slide-fade-enter-active mt-1 creator-collapsed-investigate'"
                      style="pointer-events:all;">
                      <i class="pi pi-angle-left close-investigate-window-icon"><i class="pi pi-info-circle"></i></i>
                    </div>
                  </div>
                </Transition>
                <Transition name="side-slide-fade">
                  <div :class="'investigate-details col-12 card side-slide-fade-enter-active mt-1'"
                    style="pointer-events:all;" v-if="isNodeDetails" id="node_details_div">
                    <i class="pi pi-angle-right close-investigate-window-icon"
                      @click="isNodeDetails = false; unfocusSelectedNode()"></i>
                    <!-- Node type -->
                    <h6 style="font-weight: bold; color: lightgray;">{{ selectedNode.data.type.toUpperCase() }}</h6>
                    <!-- If title and severity -->
                    <div class="investigate-details-title"
                      v-if="selectedNode.data.object.title && selectedNode.data.object.severity">

                      <h5 v-if="selectedNode.data.object.severity == 0" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#aeb5c1"></i>&nbsp;{{ selectedNode.data.object.title
                        }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 1" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#6dc193"></i>&nbsp;{{ selectedNode.data.object.title
                        }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 2" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#f7b34c"></i>&nbsp;{{ selectedNode.data.object.title
                        }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 3" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#ef6154"></i>&nbsp;{{ selectedNode.data.object.title
                        }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 4" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#b83c32"></i>&nbsp;{{ selectedNode.data.object.title
                        }}
                      </h5>
                    </div>

                    <!-- If only title -->
                    <div class="investigate-details-title"
                      v-if="selectedNode.data.object.title && !selectedNode.data.object.severity">

                      <h5 class="mb-0">
                        {{ selectedNode.data.object.title }}
                      </h5>
                    </div>

                    <!-- If name and criticality -->
                    <div class="investigate-details-title"
                      v-if="selectedNode.data.object.name && selectedNode.data.object.criticality">
                      <h5 v-if="selectedNode.data.object.criticality == 0" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#aeb5c1"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.criticality == 1" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#6dc193"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.criticality == 2" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#f7b34c"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.criticality == 3" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#ef6154"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.criticality == 4" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#b83c32"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                    </div>

                    <!-- If name and severity -->
                    <div class="investigate-details-title"
                      v-if="selectedNode.data.object.name && selectedNode.data.object.severity">
                      <h5 v-if="selectedNode.data.object.severity == 0" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#aeb5c1"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 1" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#6dc193"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 2" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#f7b34c"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 3" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#ef6154"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                      <h5 v-if="selectedNode.data.object.severity == 4" class="mb-0">
                        <i class="pi pi-circle-fill" style="color:#b83c32"></i>&nbsp;{{ selectedNode.data.object.name }}
                      </h5>
                    </div>

                    <!-- If user -->
                    <div class="investigate-details-title" v-if="selectedNode.data.object.full_name">
                      <div class="row m-0 p-0">
                        <div class="col-2 m-0 p-0">
                          <li class="user-container-img" style="padding-left: 0px;">
                            <Avatar v-if="selectedNode.data.object.picture == null"
                              :label="selectedNode.data.object.full_name[0].toUpperCase()" shape="circle"
                              size="large" />
                            <img v-else :src="getSourceLogo(selectedNode.data.object.picture)" class="rounded-circle"
                              height="35" width="35" />
                          </li>
                        </div>
                        <div class="col-10 m-0 p-0">
                          <h5 class="mb-0" style="margin-left: -15px;"> {{ selectedNode.data.object.full_name }}</h5>
                          <h6 class="mb-0" style="margin-left: -15px;"><i>{{ selectedNode.data.object.email }}</i></h6>
                        </div>
                      </div>
                    </div>

                    <!-- If only name -->
                    <div class="investigate-details-title"
                      v-if="selectedNode.data.object.name && (!selectedNode.data.object.criticality && !selectedNode.data.object.severity)">
                      <h5 class="mb-0">
                        {{ selectedNode.data.object.name }}
                      </h5>
                    </div>

                    <!-- If not an object -->
                    <div class="investigate-details-title" v-if="selectedNode.type == 'none'">
                      <h5 class="mb-0">
                        {{ selectedNode.label }}
                      </h5>
                    </div>

                    <div class="investigate-details-description" v-if="selectedNode.data.object.description">
                      {{ selectedNode.data.object.description }}
                    </div>
                    <div class="investigate-details-description" v-if="selectedNode.data.description">
                      {{ selectedNode.data.description }}
                    </div>

                    <div class="investigate-details-category" v-if="selectedNode.data.object.id">
                      <p class="category-title">{{ $t('ID') + ': ' }}</p>
                      <p class="category-value details-id"
                        @click="$router.push({ path: selectedNode.data.type + '-details', query: { id: selectedNode.data.object.id } })">
                        {{ selectedNode.data.object.id }}</p>
                    </div>

                    <div class="row m-0 p-0">
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.asset">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('asset') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.asset.name }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.hostname">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('hostname') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.hostname }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.type">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('type') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.type }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.os">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('os') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.os }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.location">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('location') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.location.city }}, {{
              selectedNode.data.object.location.country }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.intake_method">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('intakeMethod') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.intake_method.name }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.intake_schema">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('intakeSchema') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.intake_schema.name }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.nifi_listener">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('nifiListener') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.nifi_listener }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.source">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('source') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.source.name }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.created_at">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('createdAt') + ': ' }}</p>
                          <p class="category-value">{{ formatDate(selectedNode.data.object.created_at) }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.author">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('author') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.author }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.output_index">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('outputIndex') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.output_index }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.category">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('category') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.category }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.phone">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('phone') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.phone }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.slack_uname">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('slackUsername') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.slack_uname }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.country">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('country') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.country }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.gender">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('gender') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.gender }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.birthdate">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('birthdate') + ': ' }}</p>
                          <p class="category-value">{{ formatDate(selectedNode.data.object.birthdate) }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.correlation_rule">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('correlationRule') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.correlation_rule.title }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.detection_rule">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('detectionRule') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.detection_rule.title }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.team">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('team') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.team.name }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.model_class">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('modelClass') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.model_class }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.model_path">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('modelPath') + ': ' }}</p>
                          <p class="category-value">{{ selectedNode.data.object.model_path }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.status">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('status') + ': ' }}</p>
                          <p class="category-value status-label"
                            v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'New'"
                            style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            New</p>
                          <p class="category-value status-label"
                            v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Mitigated'"
                            style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            Mitigated</p>
                          <p class="category-value status-label"
                            v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Partial mitigated'"
                            style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            Partial Mitigated</p>
                          <p class="category-value status-label"
                            v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Active'"
                            style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            Active</p>
                          <p class="category-value status-label"
                            v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Closed'"
                            style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            Closed</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.is_active != undefined">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('isActive') + ': ' }}</p>
                          <p class="category-value status-label" v-if="selectedNode.data.object.is_active"
                            style="background:#6dc193; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            {{ $t('active') }}</p>
                          <p class="category-value status-label" v-if="!selectedNode.data.object.is_active"
                            style="background:#ef6154; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            {{ $t('inactive') }}</p>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.at_risk != undefined">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('atRisk') + ': ' }}</p>
                          <p class="category-value status-label" v-if="selectedNode.data.object.at_risk"
                            style="background:#6dc193; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            {{ $t('no') }}</p>
                          <p class="category-value status-label" v-if="!selectedNode.data.object.at_risk"
                            style="background:#ef6154; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                            {{ $t('yes') }}</p>
                        </div>
                      </div>

                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.ip_addr">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('ipAddress') + ': ' }}</p>
                          <div class="tag-cont">
                            <p class="details-tag" v-for="ip in selectedNode.data.object.ip_addr" :key="ip"
                              style="display:inline-block;">{{ ip }}&nbsp;</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.mac_addr">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('macAddress') + ': ' }}</p>
                          <div class="tag-cont">
                            <p class="details-tag" v-for="mac in selectedNode.data.object.mac_addr" :key="mac"
                              style="display:inline-block;">{{ mac }}&nbsp;</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 m-0 p-0" v-if="selectedNode.data.object.filters">
                        <div class="investigate-details-category">
                          <p class="category-title">{{ $t('filters') + ': ' }}</p>
                          <div class="tag-cont">
                            <p class="details-tag" v-for="filter in selectedNode.data.object.filters" :key="filter"
                              style="display:inline-block;">{{ filter }}&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="aux-margin mt-3">
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.users">
                      <p class="category-title">{{ $t('users') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.users" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['full_name', 'email']" responsive-layout="scroll">
                        <Column field="full_name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'profile', query: { id: data.id } });"
                              class="row-name-link">{{ formatString(data.full_name) }}</span>
                          </template>
                        </Column>
                        <Column field="email" :header="$t('email')" sortable>
                          <template #body="{ data }">
                            {{ data.email }}
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('user')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.incidents">
                      <p class="category-title">{{ $t('incidents') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.incidents" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['severity', 'title']" responsive-layout="scroll">
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="title" :header="$t('title')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'incident-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.title }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('incident')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.events">
                      <p class="category-title">{{ $t('events') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.events" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['severity', 'title']" responsive-layout="scroll">
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="title" :header="$t('title')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'event-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.title }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('event')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.assets">
                      <p class="category-title">{{ $t('assets') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.assets" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['criticality', 'name']" responsive-layout="scroll">
                        <Column field="criticality" :header="$t('criticality')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.criticality == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.criticality == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.criticality == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.criticality == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.criticality == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="full_name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'asset-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.name }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('asset')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.sources">
                      <p class="category-title">{{ $t('sources') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.sources" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['criticality', 'sources']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'source-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.name }}</span>
                          </template>
                        </Column>
                        <Column field="category" :header="$t('category')" sortable>
                          <template #body="{ data }">
                            {{ data.category }}
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('source')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.correlation_rules">
                      <p class="category-title">{{ $t('correlationRules') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.correlation_rules" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['severity', 'title']" responsive-layout="scroll">
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="title" :header="$t('title')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'correlation-rule-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.title }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('correlation_rule')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.machine_learning_jobs">
                      <p class="category-title">{{ $t('machineLearningJobs') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.machine_learning_jobs" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['severity', 'title']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span
                              @click="$router.push({ path: 'machine-learning-jobs-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.name }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('machine_learning_job')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.alerts">
                      <p class="category-title">{{ $t('alerts') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.alerts" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['title']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span
                              @click="$router.push({ path: 'machine-learning-jobs-details', query: { id: data.id } });"
                              class="row-name-link">{{ data.name }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('alert')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.teams">
                      <p class="category-title">{{ $t('teams') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.teams" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['name']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'manage-roles' });" class="row-name-link">{{ data.name
                              }}</span>
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('team')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.roles">
                      <p class="category-title">{{ $t('roles') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.roles" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['name']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'manage-roles' });" class="row-name-link">{{ data.name
                              }}</span>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.intake_schemas">
                      <p class="category-title">{{ $t('intakeSchemas') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.intake_schemas" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['name']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            {{ data.name }}
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('intake_schema')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.intake_methods">
                      <p class="category-title">{{ $t('intakeMethods') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.intake_methods" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['name']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            {{ data.name }}
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('intake_method')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.listeners">
                      <p class="category-title">{{ $t('listeners') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.listeners" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['name']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            {{ data.name }}
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('intake_method')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <div class="investigate-details-category investigate-details-table"
                      v-if="selectedNode.data.object.machine_learning_tasks">
                      <p class="category-title">{{ $t('machineLearningJobs') + ': ' }}</p>
                      <DataTable :value="selectedNode.data.object.machine_learning_tasks" :paginator="true"
                        class="p-datatable-customers mt-0" :rows="5" dataKey="id"
                        paginator-template="PrevPageLink PageLinks NextPageLink" :rows-per-page-options="[10, 25, 50]"
                        :global-filter-fields="['name']" responsive-layout="scroll">
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            {{ data.index_rf }}
                          </template>
                        </Column>
                        <Column field="addNode" header=" ">
                          <template #body="{ data }">
                            <i class="pi pi-plus" style="cursor: pointer;" v-tooltip.top="$t('addElementToCanvas')"
                              @click="newNodeSelectedObject = data; newNode('intake_method')"></i>
                          </template>
                        </Column>
                      </DataTable>
                    </div>

                    <!-- ------------------------ Node options ------------------------ -->
                    <div class="node-options">
                      <p class="node-options-title" @click="openOptions = !openOptions" style="cursor:pointer;"><i
                          class="pi pi-cog" style="font-size:0.9em"></i> {{
              $t('nodeOptions') }}<i class="pi pi-angle-down" v-if="!openOptions" style="float:right"></i><i
                          class="pi pi-angle-up" v-if="openOptions" style="float:right"></i></p>
                      <hr class="mb-0 mt-0">
                      <section v-if="openOptions">
                        <p class="node-options-subtitle">General</p>
                        <div class="general-node-options m-0 p-0">
                          <Button @click="deleteNode(selectedNode.id)" class="btn-custom-cancel node-options-button"
                            icon="pi pi-trash" :label="$t('delete') + ' ' + $t('node')"></Button>
                          <Button @click="exploreNode(selectedNode.id, selectedNodeType)"
                            class="btn-custom-cancel node-options-button" icon="pi pi-eye"
                            :label="$t('explore') + ' ' + $t('item')"></Button>
                        </div>

                        <div class="row p-0 m-0">
                          <div class="col-6 p-0 m-0 node-colorpicker">
                            <p class="node-options-subtitle">{{ $t('node') }} {{ $t('color') }}</p>
                            <ColorPicker class format="hex" v-model="selectedNodeData.icon_color"
                              @change="updateNodeColor(selectedNodeData.icon_color)" />
                          </div>
                        </div>

                        <p class="node-options-subtitle">{{ $t('handles') }}</p>
                        <div class="general-node-options m-0 p-0 flex flex-wrap justify-content-center gap-3">
                          <Checkbox v-model="selectedNodeData.handles[0]" inputId="Top" name="handles"
                            :value="selectedNodeData.handles[0]" :binary="true" />
                          <label for="Top" class="ml-2"> Top </label>
                          <Checkbox v-model="selectedNodeData.handles[1]" inputId="Right" name="handles"
                            :value="selectedNodeData.handles[1]" :binary="true" />
                          <label for="Right" class="ml-2"> Right </label>
                          <Checkbox v-model="selectedNodeData.handles[2]" inputId="Bottom" name="handles"
                            :value="selectedNodeData.handles[2]" :binary="true" />
                          <label for="Bottom" class="ml-2"> Bottom </label>
                          <Checkbox v-model="selectedNodeData.handles[3]" inputId="Left" name="handles"
                            :value="selectedNodeData.handles[3]" :binary="true" />
                          <label for="Left" class="ml-2"> Left </label>
                        </div>

                        <p class="node-options-subtitle">{{ $t('others') }}</p>
                        <div class="general-node-options m-0 p-0 flex flex-wrap justify-content-center gap-3">
                          <Checkbox v-model="selectedNodeData.warning" inputId="warning" name="handles"
                            :value="selectedNodeData.warning" :binary="true" />
                          <label for="warning" class="ml-2"> Add Warning </label>
                          <textarea v-if="selectedNodeData.warning" v-model="selectedNodeData.warning_description"
                            class="form-control" placeholder="Warning description" rows="3"></textarea>
                        </div>
                      </section>
                    </div>
                  </div>
                </Transition>
                <!-- ========================= Edge options ========================= -->
                <Transition name="">
                  <div class="row" v-if="!isEdgeOptions" @click="isEdgeOptions = true; focusSelectedEdge()">
                    <div class="col-11">
                    </div>
                    <div :class="'col-1 card side-slide-fade-enter-active mt-1 creator-collapsed-investigate'"
                      style="pointer-events:all;">
                      <i class="pi pi-angle-left close-investigate-window-icon">
                        <p class="collapsed-investigate-icon"><i> <img :src="require('@/assets/investigation/edge.svg')"
                              style="height:16px;margin-bottom: 6px;"></i></p>
                      </i>
                    </div>
                  </div>
                </Transition>
                <Transition name="side-slide-fade">
                  <div :class="'investigate-edge-options col-12 card side-slide-fade-enter-active mt-1'"
                    style="pointer-events:all;" v-if="isEdgeOptions">
                    <i class="pi pi-angle-right close-investigate-window-icon"
                      @click="isEdgeOptions = false; unfocusSelectedEdge()"></i>
                    <div class="general-node-options m-0 p-0">
                      <div class="investigate-details-category">
                        <p class="category-title">Edge options</p>
                      </div>
                      <p class="node-options-subtitle">{{ $t('handles') }} {{ $t('color') }}</p>
                      <ColorPicker class format="hex" v-model="edgeObject.style.stroke" @change="updateEdge()" />
                      <p class="node-options-subtitle">{{ $t('handles') }} {{ $t('label') }}</p>
                      <InputText v-model="edgeObject.label" @change="updateEdge()" />
                    </div>
                  </div>
                </Transition>
              </ScrollPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
import { ref } from "vue";


import { Position, Handle, Panel, PanelPosition, VueFlow, isNode, useVueFlow, useEdge, MarkerType, Elements } from '@vue-flow/core'

const { onPaneReady, onConnect, instance, addEdges, store, getNodes, nodes, onEdgeUpdate, onNodesChange, updateNode, onEdgeUpdateStart, onEdgeUpdateEnd } = useVueFlow()

onConnect((params) => {
  params.style = {
    stroke: '#B0B0B0',
  }
  params.updatable = true;
  addEdges([params])
  onEdgeUpdateEnd()
  return
})

onPaneReady(({ fitView }) => {
  fitView({ maxZoom: 1.5, offset: { x: -280, y: -50 } })
})

onNodesChange((nodes) => {
  let index = getNodes._value.findIndex(x => x.id === nodes[0].id);

  //getNodes._value[index] = getNodes._value[index]

})


const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
/* import the necessary styles for Vue Flow to work */
@import '@vue-flow/core/dist/style.css';

/* import the default theme, this is optional but generally recommended */
@import '@vue-flow/core/dist/theme-default.css';

.creator-aux-canvas-div {
  top: 0;
  margin-right: 0 !important;
}

.creator-collapsed-investigate {
  margin-left: -1em !important;
  height: 2.75em !important;
  margin-bottom: 0.75em;
}

.node-options label {
  font-size: 0.85em !important;
  margin-bottom: 2px !important;
  margin-left: -2px !important;
  margin-right: 1.5em !important;
  font-size: 0.85em !important;
}

.node-options .p-checkbox .p-checkbox-box {
  height: 15px !important;
  width: 15px !important;
}

.node-options .p-checkbox-icon {
  font-size: 11px;
}

.low-opacity {
  opacity: 0.5;
}

.node-picture {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 5px;
  margin-right: 5px;
}

.node-picture .p-avatar {
  width: 25px !important;
  height: 25px !important;
}

.user-container-img-node-details .p-avatar {
  width: 25px !important;
  height: 25px !important;
}

.investigate-details-table th {
  font-size: smaller !important;
}

.investigate-details-table td {
  font-size: smaller !important;
}

.investigate-details-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  font-size: smaller !important;
}

.investigate-details-table .p-paginator .p-paginator-pages .p-paginator-page {
  font-size: smaller !important;
}

.investigate-details-table {
  margin-top: -8px !important;
}

.investigate-details-category .details-tag {
  margin-top: 0px !important;
}

.validationflow .vue-flow__node-custominput {
  background: #fff
}

.validationflow .vue-flow__handle-connecting {
  background: #ff6060
}

.validationflow .vue-flow__handle-valid {
  background: #55dd99
}

.vue-flow__edge-text {
  font-style: italic !important;
}
.fa{
  width: 25px   !important;
  height: 22px !important;
}
.pi{
  top: 0px !important;
  bottom: 0px !important;
}
.close-investigate-window-icon{
 top: 9px !important; 
}
</style>
