import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { nextTick, ref } from 'vue';
import { useUserStore } from '../../store/store.js'
import Chart from 'primevue/chart';
import MapChart from 'vue-chart-map'

import * as $api from "../../api/api";

const axios = require('axios');

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const visibleLeft = ref(false);
        return { visibleLeft }
    },
    data() {
        return {
            teams: [],
            users: [],
            user: {
                "organization": {},
                "roles": [{
                    "name": "undefined",
                }],
                "permissions": []
            },
            recentIncidents: [{
                id: 1,
                title: "High DNS Bytes Out",
                date: "",
                time: "",
                severity: 2,
                status: [{
                    status: "",
                }],
            }, ],
            incidents: [{
                id: 1,
                title: "High DNS Bytes Out",
                date: "",
                time: "",
                severity: 2,
                assets: [],
                status: [{
                    status: "",
                }],
            }, ],
            recentDashboards: [],
            chartData: {
                datasets: [{
                    data: [40, 50, 60, 40, 60],
                    backgroundColor: ["#b83c32", "#ef6154", "#f7b34c", "#6dc193", "#aeb5c1"],
                    hoverBackgroundColor: ["#b83c32", "#ef6154", "#f7b34c", "#6dc193", "#aeb5c1"]
                }]
            },
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            },
            lineChartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                        label: 'Critical',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        tension: .4,
                        borderColor: '#b83c32'
                    },
                    {
                        label: 'High',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        fill: false,
                        tension: .4,
                        borderColor: '#ef6154'
                    },
                    {
                        label: 'Medium',
                        data: [12, 51, 62, 33, 21, 62, 45],
                        fill: false,
                        borderColor: '#f7b34c',
                        tension: .4,
                        /*backgroundColor: 'rgba(255,167,38,0.2)'*/
                    },
                    {
                        label: 'Low',
                        data: [12, 80, 41, 60, 72, 10, 35],
                        fill: false,
                        borderColor: '#6dc193',
                        tension: .4,
                    },
                    {
                        label: 'Informational',
                        data: [30, 25, 40, 80, 52, 62, 13],
                        fill: false,
                        borderColor: '#aeb5c1',
                        tension: .4,
                    }
                ]
            },
            lineChartOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            countryData: {}
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Chart,
        MapChart
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        reduceString(string, length) {
            if (string.length > length) {
                return string.substring(0, length) + "...";
            } else {
                return string;
            }
        },
        calculateRecentDashboards() {
            // Use created_at to calculate the most recent dashboards
            // Sort by created_at
            this.recentDashboards.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            // Return the first 3
            return this.recentDashboards.slice(0, 2);
        },
        calculateRecentIncidents() {
            // Use date to calculate the most recent incidents
            // Sort by date
            this.recentIncidents.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            // Return the first 3
            return this.recentIncidents.slice(0, 6);
        },
        async convertLocationToCountryCode(location) {
            // Convert location to country code
            const res = await axios.get('https://restcountries.com/v3.1/name/' + location);
            //console.log("RES: ", res.data[0].cca2);
            // return the country code
            return res.data[0].cca2;
        },
        async generateMapData() {
            // loop all assets on all incidents
            // get the country code
            // add to mapData
            let mapData = {};
            for (let incident of this.incidents) {
                for (let asset of incident.assets) {
                    //convert location to country code
                    let countryCode = await this.convertLocationToCountryCode(asset.location.country)
                        // loop mapData and add to the country code
                        //console.log("COUNTRY CODE: ", countryCode);
                        //console.log("MAP DATA: ", mapData[countryCode]);
                    if (mapData[countryCode]) {
                        mapData[countryCode] += 1;
                    } else {
                        mapData[countryCode] = 1;
                    }

                }
            }

            //console.log("MAP DATA: ", mapData);
            this.countryData = mapData
        },
        haveUserPermission(permission) {
            if (this.user.is_superuser) {
                return true;
            }

            // Remove / from permission
            permission = permission.replace("/", "");
            // Replace all - with _
            permission = permission.replace(/-/g, "_");
            //console.log("-------------> PERMISSION:", permission);

            // If path is manage-roles
            if (permission == "manage_roles:get") {
                for (let i = 0; i < this.user.permissions.length; i++) {
                    if (this.user.permissions[i].value == "user:get" || this.user.permissions[i].value == "role:get" || this.user.permissions[i].value == "team:get") {
                        return true;
                    }
                }
            }

            // Loop through user.permissions
            // Check if user.permissions[i].value is equal to permission
            for (let i = 0; i < this.user.permissions.length; i++) {
                if (this.user.permissions[i].value === permission) {
                    return true;
                }
            }
            // If it is not, return false
            return false;
        }
    },
    async mounted() {
        await nextTick();
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;
        //console.log("GET USER:", userObject);
        this.user = userObject;
        // get teams
        $api.getAllTeams().then((response) => {
            this.teams = response;
            //console.log("TEAMS: ", this.teams);
        }).catch((err) => {
            console.log(`[-] Error (getAllTeams): ${err}`);
        });
        // get users
        $api.getAllUsers().then((response) => {
            this.users = response;
            //console.log("USERS: ", this.users);
        }).catch((err) => {
            console.log(`[-] Error (getAllUsers): ${err}`);
        });
        // get dashboards
        $api.getAllDashboards().then((response) => {
            this.recentDashboards = response;
            //console.log("DASHBOARDS: ", this.recentDashboards);
            this.recentDashboards = this.calculateRecentDashboards();
        }).catch((err) => {
            console.log(`[-] Error (getAllDashboards): ${err}`);
        });

        // get incidents
        $api.getAllIncidents().then((response) => {
            this.recentIncidents = response;
            this.incidents = response;
            //console.log("INCIDENTS: ", this.incidents);
            this.recentIncidents = this.calculateRecentIncidents();
            this.generateMapData();
        }).catch((err) => {
            console.log(`[-] Error (getAllIncidents): ${err}`);
        });


    }
};
export default MyShared;