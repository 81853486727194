<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileAdversaries v-if="  mq.current  === 'mobile'"></MobileAdversaries>
  <TabletAdversaries v-if="  mq.current  === 'tablet'"></TabletAdversaries>
  <DesktopAdversaries v-if=" mq.current  === 'desktop'"></DesktopAdversaries>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileAdversaries from "../components/Adversaries/MobileAdversaries";
import TabletAdversaries from "../components/Adversaries/TabletAdversaries";
import DesktopAdversaries from "../components/Adversaries/DesktopAdversaries";

export default {
  /* Name */
  name: "Adversaries",
  inject: ["mq"],
  /* Components */
  components: {
    MobileAdversaries,
    TabletAdversaries,
    DesktopAdversaries,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
