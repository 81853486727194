<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="info-card" v-if="!close">
        <div class="col-12 p-0 m-0" style="width:100%">
          <i class="pi pi-times info-card-icon-close "
            style="float: right; cursor: pointer; z-index: 999; position:relative;" @click="close = !close"></i>
        </div>
        <div class="row">
          <i class="pi pi-info-circle info-card-icon mr-2 pl-1" style="position:absolute;"></i>
          <p class="pt-0 mt-0 mb-0" style="padding-left: 3.25em"><strong>{{ $t('eventInfoTitle') }}</strong></p>
          <p class="pt-0 mt-0" style="padding-left: 3.25em">{{ $t('eventInfoDescription') }}</p>
        </div>
      </div>
      <div class="row details-asset-title mr-0" v-if="loading_details">
        <ContentLoader height="60px"></ContentLoader>
      </div>
      <div class="row details-asset-title mr-0" v-else>
        <h3 v-if="details.severity == 0">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#aeb5c1"></i>
        </h3>
        <h3 v-if="details.severity == 1">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#6dc193"></i>
        </h3>
        <h3 v-if="details.severity == 2">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#f7b34c"></i>
        </h3>
        <h3 v-if="details.severity == 3">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#ef6154"></i>
        </h3>
        <h3 v-if="details.severity == 4">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#b83c32"></i>
        </h3>
        <p class="mb-0">
          <span class="id-prefix" style="text-decoration:none;"></span>
          <span class="details-id" style="text-decoration:none; cursor:default;">
            {{ details.id }}
          </span>
        </p>
      </div>
      <br>
      <div class="row" style="margin-left: 0px;">
        <div class="col-4 main-details-info p-0">
          <div class="scroll" style="height:auto; overflow-y:hidden; overflow-x:hidden;">
            <div class="sub-scroll p-2">
              <div class="row details-data pl-2 pr-2">
                <div class="col-6 p-2" v-if="loading_details">
                  <ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader>
                </div>
                <div class="col-6 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="type-dropdown">
                      <template #content>
                        <i class="fa-solid fa-temperature-empty subdetail-icon">
                          <p class="subdetail-name">&nbsp;{{
                            prettifyString(details.status[details.status.length - 1].status) }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="status in statuses" :key="status" @click="addNewStatus(status)">
                        {{ status.label }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6 p-2" v-if="loading_details">
                  <ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader>
                </div>
                <div class="col-6 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="criticality-dropdown">
                      <template #content>
                        <i v-if="details.severity == 0" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#aeb5c1">
                          <p class="subdetail-name" v-if="details.severity == 0">&nbsp;{{ $t('informational') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#6dc193">
                          <p class="subdetail-name" v-if="details.severity == 1">&nbsp;{{ $t('low') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#f7b34c">
                          <p class="subdetail-name" v-if="details.severity == 2">&nbsp;{{ $t('medium') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#ef6154">
                          <p class="subdetail-name" v-if="details.severity == 3">&nbsp;{{ $t('high') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#b83c32">
                          <p class="subdetail-name" v-if="details.severity == 4">&nbsp;{{ $t('critical') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="severity in criticalities" :key="severity"
                        @click="details.severity = severity.value">
                        {{ severity.label }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row details-data mt-2" v-if="loading_details">
                <ContentLoader height="25em"></ContentLoader>
              </div>
              <div class="row details-data mt-2 pl-2 pr-2" v-else style="padding-bottom:4em;">
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('statusDescription') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p v-if="locked || !status_has_changed" class="mb-1">{{
                      details.status[details.status.length - 1].description }}</p>
                    <Textarea v-if="!locked && status_has_changed"
                      v-model="details.status[details.status.length - 1].description" :disabled="locked"
                      style="width:100%;"></Textarea>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('description') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p v-if="locked" class="mb-1">{{ details.description }}</p>
                    <Textarea v-else v-model="details.description" :disabled="locked" style="width:100%;"></Textarea>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-1 main-details-title"><strong>{{ $t('createdAt') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p class="mb-1">{{ formatDate(details.created_at) }}</p>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('source') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p class="mb-1 details-id"
                      @click="$router.push({ path: 'source-details', query: { id: details.source.id } })">{{
                      details.source.name }}</p>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('asset') }}</strong></p>
                  <div class="row ml-0 mt-2">
                    <div class="detection-card">
                      <p class="mt-0 mb-0"><strong>{{ details.asset.name }}</strong></p>
                      <p class="details-id mt-0 mb-0" style="font-size: 0.8em;"
                        @click="$router.push({ path: 'asset-details', query: { id: details.asset.id } })">
                        {{ details.asset.id }}</p>
                      <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.asset.description }}</p>
                    </div>
                  </div>
                </div>
                <div class="row pr-0 mt-4">
                  <p class="mb-0  main-details-title"><strong>{{ $t('detectionRule') }}</strong></p>
                  <div class="row ml-0 mt-2">
                    <div class="detection-card">
                      <p class="mt-0 mb-0"><strong>{{ details.detection_rule.title }}</strong></p>
                      <p class="details-id mt-0 mb-0" style="font-size: 0.8em;"
                        @click="$router.push({ path: 'detection-rule-details', query: { id: details.detection_rule.id } })">
                        {{ details.detection_rule.id }}
                      </p>
                      <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.detection_rule.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pt-2 mt-4 details-actions-div">
            <Button v-if="!locked" class="btn-custom-save mr-2" :label="$t('save')" @click="onSaveUpdate();"></Button>
            <Button v-if="locked" class="btn-custom-save mr-2" :label="$t('edit')" @click=" unlockForm()"></Button>
            <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('cancel')"
              @click=" onCancelUpdate();"></Button>
            <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('delete')" @click=" lockForm()"
              style="float:right;" icon="pi pi-trash"></Button>
          </div>
        </div>

        <div class="col-8 pt-0 pb-0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <div class="sec-details-info">
              <TabView>
                <!-- ====================================================================== Incidents =========================================================================== -->
                <TabPanel :header="$t('incidents')">
                  <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y: auto; overflow-x:hidden;">
                    <div class="aux p-0">
                      <div class="col-12 pl-0 pr-0 text-center" v-if="loading_details">
                        <ContentLoader></ContentLoader>
                      </div>
                      <DataTable v-else :value="details.incidents" :paginator="true"
                        class="p-datatable-customers mt-2" :rows="15" :row-hover="true" dataKey="id"
                        filter-display="menu" :loading="loading_details"
                        paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                        :rows-per-page-options="[10, 25, 50]"
                        current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                        :global-filter-fields="['id', 'name', 'os', 'host', 'type', 'criticality', 'last_act_date', 'risk']"
                        responsive-layout="scroll" @row-click=" setSelectedData($event.data)">
                        <template #empty>
                          {{ $t('noDataFound') }}
                        </template>
                        <template #loading>
                          {{ $t('loadingData') }}
                        </template>
                        <Column field="title" :header="$t('title')" sortable>
                          <template #body="{ data }">
                            <span @click=" $router.push({ path: 'incident-details', query: { id: data.id } })"
                              class="row-name-link">{{
                                formatString(data.title)
                              }} </span>
                          </template>
                        </Column>
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="correlation_rule" :header="$t('correlationRule')" sortable>
                          <template #body="{ data }">
                            <span v-tooltip.top="data.correlation_rule.title">
                              {{ formatString(data.correlation_rule.title) }}
                            </span>
                          </template>
                        </Column>
                        <Column field="created_at" :header="$t('createdAt')" sortable>
                          <template #body="{ data }">
                            {{ formatDate(data.created_at) }}
                          </template>
                        </Column>
                        <Column field="status" :header="$t('status')" sortable>
                          <template #body="{ data }">
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'New'"
                              style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">New</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Mitigated'"
                              style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Mitigated</span>
                            <span
                              v-if="prettifyString(data.status[data.status.length - 1].status) == 'Partial mitigated'"
                              style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Partial
                              Mitigated</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Active'"
                              style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Active</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Closed'"
                              style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Closed</span>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </div>
                </TabPanel>
                <!-- ====================================================================== Status history =========================================================================== -->
                <TabPanel :header="$t('statusHistory')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <Timeline :data="status_timeline" :title="$t('')" router_type="status" v-if="!loading_details">
                  </Timeline>
                  <ScrollPanel style="width: 100%; height:25em; margin-top:-4em;" class="custom"
                    v-if="!loading_details">
                    <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y:auto;">
                      <div class="aux p-0" style="height:35vh;">
                        <OpenGroup v-for="( status, index ) in details.status.slice().reverse() " :key="status"
                          :groupName="status.status + ' - ' + status.date" :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p v-if="prettifyString(status.status) == 'New'"
                              style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              New</p>
                            <p v-if="prettifyString(status.status) == 'Mitigated'"
                              style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Mitigated</p>
                            <p v-if="prettifyString(status.status) == 'Partial mitigated'"
                              style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Partial Mitigated</p>
                            <p v-if="prettifyString(status.status) == 'Active'"
                              style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Active</p>
                            <p v-if="prettifyString(status.status) == 'Closed'"
                              style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Closed</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.date }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Logs =========================================================================== -->
                <TabPanel :header="$t('logs')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel style="width: 100%; height:39em" class="custom" v-else>
                    <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y:auto; height: 65vh">
                      <div class="aux p-0">
                        <OpenGroup v-for="(log, index) in details.logs" :key="log" :groupName="log.id"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('assetId') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link"
                              @click="$router.push({ path: 'asset-details', query: { id: data.id } })">{{ log.asset_id
                              }}</p>
                            <p class="p-0 m-0 mt-2 group-title"><strong>{{ $t('hostname') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ log.log.asset.hostname }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ formatDate(log.log.date) }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <TabPanel header="MITRE ATT&CK">
                  <div class="mitre-tab pl-2 pr-2 pt-1 pb-4" style="overflow-y:auto; height: 65vh">
                    <h6 class="mt-3 ml-0 mitre-title">{{ $t('tacticsAndTechniquesObserved') }}</h6>
                    <div class="mitre-matrix" v-if="!mitre_available">{{ $t('noTacticsAndTechniquesObserved') }}</div>
                    <div class="mitre-matrix" v-else>
                      <div class="card p-2" :tactic="tactic" v-for="tactic in mitre_matrix" :key="tactic.id" :active="tactic.active">
                        <p class="card-tactic-name">{{ tactic.name }} <span class="card-tactic-id">({{ tactic.id }})</span></p>
                        <p class="card-tactic-url" @click="openRoute(tactic.url)">{{ tactic.url }}</p>
                        <p class="card-tactic-description">{{ tactic.description }}</p>
                        <h5 class="mb-0 mt-3 card-tactic-section" v-if="tactic.techniques.length>0" @click="tactic.open=!tactic.open">{{ $t('techniques') }} <span><v-icon :name="tactic.open ? 'bi-chevron-up':'bi-chevron-down'"></v-icon></span></h5>
                        <hr class="mt-0 mb-1" v-if="tactic.techniques>0">
                        <div class="card technique-card p-2" v-for="technique in tactic.techniques" :key="technique.id" style=" background-color: #f2f2f2 !important" v-if="tactic.open">
                          <p class="card-technique-name">{{ technique.name }} <span class="card-technique-id">({{ technique.id }})</span></p>
                          <p class="card-technique-url" @click="openRoute(technique.url)">{{ technique.url }}</p>
                          <p class="card-technique-description">{{ technique.description }}</p>
                          <h6 class="mb-0 mt-3 card-tactic-section" v-if="technique.subtechniques.length>0" @click="technique.open=!technique.open">{{ $t('subtechniques') }} <span><v-icon :name="technique.open ? 'bi-chevron-up':'bi-chevron-down'"></v-icon></span></h6>
                          <hr class="mt-0 mb-1" v-if="technique.subtechniques.length>0">
                          <div class="card subtechnique-card p-2" v-for="subtechnique in technique.subtechniques" :key="subtechnique.id" style=" background-color: #e3e3e3 !important"  v-if="technique.open">
                            <p class="card-subtechnique-name">{{ subtechnique.name }} <span class="card-subtechnique-id">({{ subtechnique.id }})</span></p>
                            <p class="card-subtechnique-url" @click="openRoute(subtechnique.url)">{{ subtechnique.url }}</p>
                            <p class="card-subtechnique-description">{{ subtechnique.description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
import Tactic from "../Partials/Mitre/Tactic.vue";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.mitre-title {
  font-size: 1em;
  font-weight: bold;
  color: #212529;
  margin-left: 1em;
}

.mitre-matrix {
  display: flex;
}
.card-tactic-name{
  font-weight: 600;
  font-size: 1.25em;
}
.card-tactic-id{
  font-weight: 400;
  font-size: .9em;
  color: #7e868d;
}
.card-tactic-description{
  font-size: 1em;
}
.card-tactic-url{
  color: #007bff;
  cursor: pointer;
}
.card-tactic-url:hover{
  text-decoration: underline;
}
.technique-card {
  background-color: #f3f3f3 !important;
}
.card-technique-name{
  font-weight: 600;
  font-size: .9em;
}
.card-technique-id{
  font-weight: 400;
  font-size: .75em;
  color: #7e868d;
}
.card-technique-description{
  font-size: .9em;
}
.card-technique-url{
  color: #007bff;
  cursor: pointer;
  font-size: .9em;
}
.card-technique-url:hover{
  text-decoration: underline;
}

.card-subtechnique-name{
  font-weight: 600;
  font-size: .9em;
}
.card-subtechnique-id{
  font-weight: 400;
  font-size: .75em;
  color: #7e868d;
}
.card-subtechnique-description{
  font-size: .9em;
}
.card-subtechnique-url{
  color: #007bff;
  cursor: pointer;
  font-size: .9em;
}
.card-subtechnique-url:hover{
  text-decoration: underline;
}
.card-tactic-section{
  cursor: pointer;
}
</style>
