<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <Dialog header="Header" v-model:visible="displayModal" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }"
   :modal="true" :draggable="false" :dismissableMask="true" :showHeader="false" :closeOnEscape="true">
    <slot name="body" />
    <template #footer>
      <slot name="footer" />
    </template>
  </Dialog>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import { ref } from 'vue';
export default {
  setup() {
    const displayModal = ref(false);

    const openBasic = () => {
      displayModal.value = true;
    };
    const closeBasic = () => {
      displayModal.value = false;
    };
    return { displayModal, openBasic, closeBasic };
  },
  name: "Modal",
  data() {
        return {
            user: {
                "permissions": []
            },
        };
    },
  methods: {
    closeModal() {
      this.closeBasic()
    },
    openModal() {
      this.openBasic()
    }
  },
  props: {
  },
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>

p {
  margin: 0;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog .p-button {
  min-width: 6rem;
}
.p-card-content {
  padding: 0 !important; 
}
</style>