<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <MobileDashboardViewerNoLogin v-if="  mq.current  === 'mobile'"></MobileDashboardViewerNoLogin>
    <TabletDashboardViewerNoLogin v-if="  mq.current  === 'tablet'"></TabletDashboardViewerNoLogin>
    <DesktopDashboardViewerNoLogin v-if=" mq.current  === 'desktop'"></DesktopDashboardViewerNoLogin>
  </template>
  
  <!----------------------------------------------->
  <!--                    SCRIPT                 -->
  <!----------------------------------------------->
  <script>
  import MobileDashboardViewerNoLogin from "../components/DashboardViewerNoLogin/MobileDashboardViewerNoLogin";
  import TabletDashboardViewerNoLogin from "../components/DashboardViewerNoLogin/TabletDashboardViewerNoLogin";
  import DesktopDashboardViewerNoLogin from "../components/DashboardViewerNoLogin/DesktopDashboardViewerNoLogin";
  
  export default {
    /* Name */
    name: "DashboardViewerNoLogin",
    inject: ["mq"],
    /* Components */
    components: {
      MobileDashboardViewerNoLogin,
      TabletDashboardViewerNoLogin,
      DesktopDashboardViewerNoLogin,
    },
  };
  </script>
  
  <script setup>
  import { useMq } from "vue3-mq";
  const mq = useMq();
  </script>
  
  <!----------------------------------------------->
  <!--                    STYLES                 -->
  <!----------------------------------------------->
  <style scoped >
  </style>
  