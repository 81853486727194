<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="adversary-card row">
        <div class="col-2 adversary-left-col">
            <img class="nav-bar-logo" v-bind:src="adversaryTempImg" alt="logo" />
            <p class="adversary-img-caption">{{ $t('seenInYourCompany') }}</p>
            <div class="row text-center pl-2 pr-2">
                <div class="col-4 adversary-resume-icon">
                    <p><v-icon name="md-sunny" scale="1.25"></v-icon></p>
                    <p>{{adversary.intel_reports}}</p>
                </div>
                <div class="col-4 adversary-resume-icon">
                    <p><v-icon name="md-assignmentlate" scale="1.25"></v-icon></p>
                    <p>{{adversary.intel_reports}}</p>
                </div>
                <div class="col-4 adversary-resume-icon">
                    <p><v-icon name="md-description" scale="1.25"></v-icon></p>
                    <p>{{adversary.intel_reports}}</p>
                </div>
            </div>
        </div>
        <div class="col-10 adversary-right-col pl-3">
            <h6 class="adversary-name">{{adversary.name}}</h6>
            <p class="adversary-description">{{adversary.description}}</p>
            <div class="row adversary-data w-100">
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('last_active')}}</p>
                    <p class="adversary-data-value">{{adversary.last_active}}</p>
                    <p class="adversary-data-title">{{$t('intel_reports')}}</p>
                    <p class="adversary-data-value">{{adversary.intel_reports}}</p>
                    <p class="adversary-data-title">{{$t('type')}}</p>
                    <p class="adversary-data-value">{{adversary.type}}</p>
                </div>
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('status')}}</p>
                    <p class="adversary-data-value">{{adversary.status}}</p>
                    <p class="adversary-data-title">{{$t('target_industry')}}</p>
                    <p class="adversary-data-value">{{adversary.target_industry}}</p>
                    <p class="adversary-data-title">{{$t('motivation')}}</p>
                    <p class="adversary-data-value">{{adversary.motivation}}</p>
                </div>
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('origin')}}</p>
                    <p class="adversary-data-value">{{adversary.origin}}</p>
                    <p class="adversary-data-title">{{$t('target_counties')}}</p>
                    <p class="adversary-data-value">{{adversary.target_counties}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
export default {
    name: "AdversaryCard",
    data() {
        return {
            adversaryTempImg:  require('@/assets/user-default-img.png'),
        }
    },
    components: {
    },
    methods: {

    },
    props: {
        adversary: Object,
    },
    mounted(){
        
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.adversary-card {
    padding: 1rem;
    margin: 0.5rem;
    width: 48rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}
.adversary-left-col {
    padding: 0;
    margin: 0;
    text-align: center;
}
.adversary-right-col {
    padding: 0;
    margin: 0;
}
.adversary-left-col img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
}
.adversary-resume-icon{
    padding: 0rem;
    text-align: center;
}
.adversary-img-caption{
    font-size: 0.75rem;
    margin: 0.5rem;
    color: #999ea3;
}
.adversary-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
}
.adversary-description {
    font-size: 1rem;
    margin: 0;
}
.adversary-data-title{
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0;
    margin-top: 0.5rem;
    color: #999ea3;
}
</style>