import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import CustomChart from "../../components/Partials/CustomChart/CustomChart.vue"
import Chart from 'primevue/chart';
import Modal from '../../components/Partials/Modals/Modal.vue'
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'

const axios = require('axios');
import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            recording_src: 'test.mp4',
            currentTime: 0,
            recording: null,
            checkpoints: [],
            checkpoint_name: '',
            editMode: false,
            showDateConfig: false,
            selectedId: '',
            start_date: null,
            end_date: null,
            powerOnAssets: 0,
            actual_end_date: null,
            actual_start_date: null,
            recording_err: false,
            loading_video: false,
            uploadReady: false,
            uploadError: false,
            uploadLoading: false,
            showActions: true,
            actions: [{
                    label: this.$t('newAsset'),
                    icon: 'pi pi-plus',
                    callback: () => { this.createData() },
                    permit: false
                },
                {
                    label: this.$t('editMode'),
                    icon: 'pi pi-pencil',
                    callback: () => { this.changeToEditMode() },
                    permit: false
                }
            ],
            items: [{
                    label: this.$t('details'),
                    step: 0
                },
                {
                    label: this.$t('detectionRules'),
                    step: 1
                },
                {
                    label: this.$t('correlationRules'),
                    step: 2
                },
                {
                    label: this.$t('assets'),
                    step: 3
                },
                {
                    label: this.$t('users'),
                    step: 4
                }
            ],
            step: 0,
            viewmode: 0,
            viewmodemenu: false,
            tab_position: true,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                os: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                host: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                type: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                criticality: {
                    operator: FilterOperator.OR,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                last_act_date: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            selectedData: null,
            atRiskAssets: 0,
            selectedGroups: [],

            hours: [
                this.$t('all_time'),
                this.$t('last_12_hours'),
                this.$t('last_24_hours'),
                this.$t('last_48_hours'),
                this.$t('last_7_days'),
                this.$t('last_1_month'),
            ],
            lastHours: this.$t('all_time'),
            data: [],
            data_backup: {},
            details: {
                "id": 0,
                "description": '',
                "name": '',
                "os": '',
                "host": '',
                "type": '',
                "location": {
                    "country": '',
                    "city": '',
                    "latitude": 0,
                    "longitude": 0
                },
                "criticality": '',
                "tags": [],
                "ip_addr": [],
                "mac_addr": [],
                "first_date": '',
                "last_date": '',
                "asset_groups": [{
                    "description": "",
                    "name": "",
                    "organization_id": "",
                    "tags": [],
                    "id": ""
                }],
                "asset_events": [{
                    "title": "",
                    "description": "",
                    "severity": 0,
                    "detection_rule_id": "",
                    "created_at": "2022-11-09T11:39:01",
                    "status": [{
                        "status": "",
                        "description": "",
                        "user": "",
                        "date": ""
                    }],
                    "id": "",
                    "organization_id": ""
                }],
                "asset_incidents": [{
                    "title": "",
                    "description": "",
                    "severity": 1,
                    "status": [{
                        "status": "",
                        "description": ""
                    }],
                    "id": "",
                    "team_id": "",
                    "correlation_rule_id": "",
                    "events": [
                        ""
                    ]
                }]
            },
            groups: [],
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            loading_table: false,
            loading_details: false,
            new_name: '',
            new_hostname: '',
            new_description: '',
            new_tags: [],
            new_ips: [],
            new_macs: [],
            new_type: '',
            new_location: '',
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        CustomChart,
        Chart,
        Modal,
        ConfirmDialog,
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        theFormat(number) {
            return number.toFixed(2);
        },
        async formatCountry(country) {
            if (country == null) {
                return '';
            }
            // Convert location to country code
            const res = await axios.get('https://restcountries.com/v3.1/name/' + country);
            //console.log("RES: ", res.data[0].cca2);
            // return the country code
            return res.data[0].cca2;
        },
        setSelectedData(data) {
            this.loading_details = true;
            this.loading_details = false;
            this.details = data;
        },
        checkDate(date) {
            // Check if date is before ten minutes ago
            let d = new Date(date);
            let now = new Date();
            let diff = now - d;
            let minutes = diff / 1000 / 60;
            if (minutes < 10) {
                return true;
            }
            return false;
        },
        decreaseOneHour(time) {
            // Decrease one hour from date
            let d = new Date(time);
            d.setHours(d.getHours() - 1);
            return d;
            //let d = new Date(time);
            //d.setDate(d.getDate() - 1);
            //return d;
        },
        decreseFiveMinutes(time) {
            // Decrease five minutes from date
            let d = new Date(time);
            d.setMinutes(d.getMinutes() - 5);
            return d;
        },
        formatDate(date) {
            let d = new Date(date);
            // Format date to dd/mm/yyyy hh:mm
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            return `${year}/${month}/${day} ${hour}:${minutes < 10 ? "0" + minutes : minutes}`;
        },
        setSelectedToEdit(data) {
            this.toEditData = this.selectedData;
        },

        setLastHours(hour) {
            this.loading_table = true;
            this.lastHours = hour;
            let sdate = new Date();
            let edate = new Date();

            switch (this.lastHours) {
                case this.$t('last_12_hours'):
                    sdate.setHours(sdate.getHours() - 12);
                    break;
                case this.$t('last_24_hours'):
                    sdate.setHours(sdate.getHours() - 24);
                    break;
                case this.$t('last_48_hours'):
                    sdate.setHours(sdate.getHours() - 48);
                    break;
                case this.$t('last_7_days'):
                    sdate.setDate(sdate.getDate() - 7);
                    break;
                case this.$t('last_1_month'):
                    sdate.setMonth(sdate.getMonth() - 1);
                    break;
                case this.$t('all_time'):
                    sdate.setFullYear(sdate.getFullYear() - 10);
                    break;
            }

            // Format dates to yyyy-mm-ddThh:mm:ss
            sdate = sdate.toISOString().split('.')[0];
            edate = edate.toISOString().split('.')[0];

            $api.getAllAssets(true, sdate).then((response) => {
                this.data = response;
                if (this.data) {
                    this.details = this.data[0];
                    this.calcPercentageByCriticality();
                    this.createChartsData();
                }

                this.loading_table = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssets): ${err}`);
            });
        },

        /*
        loadGroups() {
            // Load groups from data 
            let groups = [];
            // loop through data
            this.data.forEach((item) => {
                // loop through asset_groups
                item.asset_groups.forEach((group) => {
                    // check if group is already in groups
                    // loop through groups
                    let found = false;
                    groups.forEach((g) => {
                        if (g.id == group.id) {
                            found = true;
                        }
                    });
                    if (!found) {
                        // if not, add it
                        groups.push({
                            'id': group.id,
                            'name': group.name
                        });
                    }
                });
            });
            // set groups
            this.groups = groups;
        },
        */
        filterByGroup(group_ids, add) {
            // If no group is selected, show all assets
            if (group_ids.length == 0) {
                this.data = this.data_backup;
                this.calcPercentageByCriticality();
                this.getAtRiskAssets();
                return;
            }

            // Other case 
            // If is clicked on group tab
            if (add) {
                // find group id in groups and add it to selectedGroups
                this.groups.forEach((group) => {
                    if (group.id == group_ids[0].id) {
                        this.selectedGroups.push(group);
                    }
                });
            }
            // Get all data assets from group
            let data = this.data_backup;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data[i].asset_groups.length; j++) {
                    for (let k = 0; k < group_ids.length; k++) {
                        if (data[i].asset_groups[j].id == group_ids[k].id) {
                            // Add asset to data_filtered if not already in it
                            let found = false;
                            for (let l = 0; l < data_filtered.length; l++) {
                                if (data_filtered[l].id == data[i].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                data_filtered.push(data[i]);
                            }
                        }
                    }
                }
            }
            this.data = data_filtered;
            this.calcPercentageByCriticality();
            this.getAtRiskAssets();
        },
        getAtRiskAssets() {
            // Find all assets with risk == true
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].risk == true) {
                    data_filtered.push(data[i]);
                }
            }
            this.atRiskAssets = data_filtered.length
        },
        calcPercentageByCriticality() {
            // Calculate the percentage of each criticality
            let criticality_1 = 0;
            let criticality_2 = 0;
            let criticality_3 = 0;
            let criticality_4 = 0;
            let total = 0;
            this.data.forEach(element => {
                switch (element.criticality) {
                    case 1:
                        criticality_1++;
                        break;
                    case 2:
                        criticality_2++;
                        break;
                    case 3:
                        criticality_3++;
                        break;
                    case 4:
                        criticality_4++;
                        break;
                }
                total++;
            });

            total = criticality_1 + criticality_2 + criticality_3 + criticality_4;
            // Set the percentage of each criticality
            this.criticality_1 = (criticality_1 / total) * 100
            this.criticality_2 = (criticality_2 / total) * 100
            this.criticality_3 = (criticality_3 / total) * 100
            this.criticality_4 = (criticality_4 / total) * 100
                // Prepare for the chart
            this.criticality_1 = this.criticality_1 - 0.75;
            this.criticality_2 = this.criticality_2 - 0.75;
            this.criticality_3 = this.criticality_3 - 0.75;
            this.criticality_4 = this.criticality_4 - 0.75;
            // Number of elements 
            this.number_of_low_criticality = criticality_1;
            this.number_of_medium_criticality = criticality_2;
            this.number_of_high_criticality = criticality_3;
            this.number_of_critical_criticality = criticality_4;
        },
        createData() {
            this.$refs.createData.openModal();
        },
        changeToEditMode() {
            this.editMode = true;
            this.selectedData = null
        },
        deleteData() {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateCriticality(id, criticality) {
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].criticality = criticality;
                    $api.updateAsset(id, { "criticality": criticality }).then((response) => {
                        //console.log(`[+] Incident ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateAsset): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            this.calcPercentageByCriticality();
        },
        getCurrentTime(recording) {
            this.currentTime = recording.currentTime;
            //console.log(this.currentTime);
        },
        setVideo(recording) {
            this.recording = recording;
            //console.log('Recording seted to: ', this.recording);
        },
        moveToSecond(second) {
            this.recording.currentTime = second;
            this.currentTime = second;
        },
        setCheckpoint(name, sec) {
            //console.log("Checkpoint: ", name, sec);
            // convert sec to hh:mm:ss
            let hours = Math.floor(sec / 3600);
            let minutes = Math.floor((sec - (hours * 3600)) / 60);
            let seconds = sec - (hours * 3600) - (minutes * 60);
            // round seconds
            seconds = Math.round(seconds * 100) / 100
            let result = (hours < 10 ? "0" + hours : hours);
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);

            //console.log("Start time: ", this.actual_start_date);
            //console.log("Sec: ", sec);

            // Add sec to date
            let real_time_label = new Date(this.formatDate(this.actual_start_date));
            real_time_label.setSeconds(real_time_label.getSeconds() + sec);
            //console.log("Real time label: ", real_time_label);

            let time_label = result;
            let check = {
                name: name,
                sec: sec,
                time_label: time_label,
                real_time_label: this.formatDate(real_time_label)
            }
            this.checkpoints.push(check);
        },
        toPythonDate(date) {
            let d = new Date(date);
            let year = d.getFullYear();
            let month = d.getMonth() + 1;
            let day = d.getDate();
            let hour = d.getHours();
            let minute = d.getMinutes();
            let second = d.getSeconds();
            let result = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            return result;
        },
        toUTCDatetime(date) {
            let d = new Date(date);
            let year = d.getUTCFullYear();
            let month = d.getUTCMonth() + 1;
            let day = d.getUTCDate();
            let hour = d.getUTCHours();
            let minute = d.getUTCMinutes();
            let second = d.getUTCSeconds();
            let result = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            return result;
        },
        format2Digits(n) {
            return n < 10 ? '0' + n : n;
        },
        openRecordingModal(id) {
            this.selectedId = id;
            this.$refs.exploreDesktop.openModal();
            this.setDateInputs();
            this.loading_video = true;
            this.recording_err = false;
            //console.log("Start date: ", this.toUTCDatetime(this.details.last_date));
            $api.getAssetRecording(id, this.toUTCDatetime(this.details.last_date)).then((response) => {
                //console.log("Recording: ", response);
                if (response.length != 0) {
                    //console.log("Recording src: ", this.getSource(response[0]));
                    this.actual_start_date = this.formatDate(response.first_datetime);
                    this.actual_end_date = this.formatDate(response.last_datetime);
                    this.recording_src = this.getSource(response.videos[0]);

                } else {
                    this.recording_err = true;
                }
                this.currentTime = 0;
                this.checkpoints = [];
                this.loading_video = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssetRecording): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('recordingNotLoaded'), life: 5000 });
                this.loading_video = false;
            });
        },
        setDateInputs() {
            this.start_date = this.formatDate(this.decreseFiveMinutes(this.details.last_date))
            this.end_date = this.formatDate(this.details.last_date)
            this.actual_start_date = this.formatDate(this.decreseFiveMinutes(this.details.last_date))
            this.actual_end_date = this.formatDate(this.details.last_date)
        },
        setNewDates(start, end) {
            this.loading_video = true;
            this.recording_err = false;
            //console.log("Start date: ", this.toUTCDatetime(start));
            //console.log("End date: ", this.toUTCDatetime(end));
            // Api call
            $api.getAssetRecording(this.selectedId, this.toUTCDatetime(start), this.toUTCDatetime(end)).then((response) => {
                //console.log("Recording: ", response);
                if (response.length != 0) {
                    //console.log("Recording src: ", this.getSource(response.videos[0]));
                    this.actual_start_date = this.formatDate(response.first_datetime);
                    this.actual_end_date = this.formatDate(response.last_datetime);
                    this.recording_src = this.getSource(response.videos[0]);

                } else {
                    this.recording_err = true;
                }
                this.currentTime = 0;
                this.checkpoints = [];
                this.loading_video = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssetRecording): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('recordingNotLoaded'), life: 3000 });
                this.loading_video = false;
            });

        },
        getPowerOnAssets() {
            // loop data
            for (let i = 0; i < this.data.length; i++) {
                //console.log(this.data[i].last_date);
                if (this.checkDate(this.data[i].last_date)) {
                    this.powerOnAssets++;
                }
            }
        },
        getSource(src) {
            return process.env.VUE_APP_BASE_URL + '/' + src;
        },
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // get assets from API
        if (process.env.VUE_APP_ENV === 'development') {
            await (this.data = require('./assets.json'));
            await (this.data_backup = require('./assets.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllAssets(true).then((response) => {
                this.data = response;
                this.data_backup = response;

                // loop data and fprmat country to country code
                this.data.forEach((item) => {
                    this.formatCountry(item.location.country).then((res) => {
                        item.location.country = res;
                    });
                });

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                if (this.data)
                    this.details = this.data[0];
                //wait for 5 secs
                this.loading_table = false;
                //this.loadGroups();
                this.calcPercentageByCriticality();
                this.getPowerOnAssets();
                this.lastHours = this.hours[0];

            }).catch((err) => {
                console.log(`[-] Error (getAllAssets): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

        // get asset_groups from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.asset_groups = require('./asset_groups.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            $api.getAllAssetGroups(true).then((response) => {
                this.groups = response;
                //console.log("ASSET GROUPS: ", this.groups);
            }).catch((err) => {
                console.log(`[-] Error (getAllAssetGroups): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    },
};
export default MyShared;