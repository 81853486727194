<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileReports v-if="  mq.current  === 'mobile'"></MobileReports>
  <TabletReports v-if="  mq.current  === 'tablet'"></TabletReports>
  <DesktopReports v-if=" mq.current  === 'desktop'"></DesktopReports>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileReports from "../components/Reports/MobileReports";
import TabletReports from "../components/Reports/TabletReports";
import DesktopReports from "../components/Reports/DesktopReports";

export default {
  /* Name */
  name: "Reports",
  inject: ["mq"],
  /* Components */
  components: {
    MobileReports,
    TabletReports,
    DesktopReports,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
