import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue";
import Modal from "../../components/Partials/Modals/Modal.vue";
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";

import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            its_me: false,
            user: {
                id: String,
                full_name: ' ',
                email: '',
                birthdate: '',
                phone: '',
                gender: '',
                picture_url: require('@/assets/user-default-img.png')
            },
            loading: true,
            languages: [
                { flag: require('@/assets/locale/es.svg'), language: 'es', title: 'Español' },
                { flag: require('@/assets/locale/en.svg'), language: 'en', title: 'English' },

            ],
            locale_img: require('@/assets/locale/en.svg'),
            locale_name: 'English',
            edit_full_name: false,
            edit_email: false,
            edit_birthdate: false,
            edit_phone: false,
            edit_gender: false,
            edit_pass: false,
            old_pass: '',
            new_pass: '',
            new_confirm_pass: '',
            show_pass_error: false,
            pass_error: '',
            uploadedFiles: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        /* ====================================== Picture ====================================== */
        async onAdvancedUpload(event) {
            let files = event.files[0]

            let data = new FormData()
            data.append('in_file', files)

            this.updatePicture(data)
        },
        updatePicture(result) {
            $api.newPicture(result).then(
                response => {
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    $api.getMe(true).then((response) => {
                        this.user = response;
                        //console.log("ME: ", this.user);
                        this.locale_name = this.user.language == 'en' ? 'English' : 'Español'
                        this.locale_img = require('@/assets/locale/' + this.user.language + '.svg')
                        this.loading = false;
                        this.its_me = true;
                    }).catch((err) => {
                        console.log(`[-] Error (getMe): ${err}`);
                    });
                    this.$router.go(0)
                }
            ).catch((err) => {
                console.log(`[-] Error (updateMe): ${err}`);
            })
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        /* ==================================================================================== */

        changeLanguage(language) {
            $api.updateMe({ "language": language }).then(
                response => {
                    this.setLocale(language)
                    this.$cookies.set("LANGUAGE", language);
                    this.$router.go(0)
                }
            ).catch((err) => {
                console.log(`[-] Error (updateMe): ${err}`);
            })
        },
        setLocale(locale) {
            this.setGlobalLocale(locale)
            this.locale_img = require('@/assets/locale/' + this.getGlobalLocale() + '.svg')
            this.locale_name = this.getGlobalLocale() == 'en' ? 'English' : 'Español'
        },
        logout() {
            $api.logout().then(
                response => {
                    this.$cookies.remove("ACCESS_TOKEN");
                    this.$router.push({ name: 'SignIn' });
                }
            ).catch(
                err => {
                    console.log(`[-] Error (logout): ${err}`);
                }
            )
        },
        updateFullName(its_me) {
            if (its_me) {
                $api.updateMe({ "full_name": this.user.full_name }).then(
                    response => {
                        this.edit_full_name = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                        this.$router.go(0)
                    }
                ).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                    console.log(`[-] Error (updateMe): ${err}`);
                })
            } else {
                $api.updateUser(this.user.id, { "full_name": this.user.full_name }).then(
                    response => {
                        this.edit_full_name = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                    console.log(`[-] Error (updateMe): ${err}`);
                })
            }
        },
        updateEmail(its_me) {
            if (its_me) {
                $api.updateMe({ "email": this.user.email }).then(
                    response => {
                        this.edit_email = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            } else {
                $api.updateUser(this.user.id, { "email": this.user.email }).then(
                    response => {
                        this.edit_email = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            }
        },
        updateBirthdate(its_me) {
            if (its_me) {
                $api.updateMe({ "birthdate": this.user.birthdate }).then(
                    response => {
                        this.edit_birthdate = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            } else {
                $api.updateUser(this.user.id, { "birthdate": this.user.birthdate }).then(
                    response => {
                        this.edit_birthdate = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            }
        },
        updatePhone(its_me) {
            if (its_me) {
                $api.updateMe({ "phone": this.user.phone }).then(
                    response => {
                        this.edit_phone = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            } else {
                $api.updateUser(this.user.id, { "phone": this.user.phone }).then(
                    response => {
                        this.edit_phone = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            }
        },
        updateGender(its_me) {
            if (its_me) {
                $api.updateMe({ "gender": this.user.gender }).then(
                    response => {
                        this.edit_gender = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            } else {
                $api.updateUser(this.user.id, { "gender": this.user.gender }).then(
                    response => {
                        this.edit_gender = false
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    }
                ).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            }
        },
        updatePass(its_me) {
            if (its_me) {
                $api.changePassword({
                    "old_password": this.old_pass,
                    "new_password": this.new_pass,
                    "verify_password": this.new_confirm_pass
                }).then((response) => {
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    this.edit_pass = false
                }).catch((err) => {
                    console.log(`[-] Error (updateMe): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                    this.show_pass_error = true
                    this.pass_error = err.response.data.detail
                })
            } else {
                $api.recoverPassword(this.user.email).then((response) => {
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('mailSended'), life: 3000 });
                }).catch((err) => {
                    console.log(`[-] Error (recoverPassword): ${err}`);
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                })
            }

        }

    },
    mounted() {
        // Check if is a query param
        if (this.$route.query.id) {
            //console.log("ID: ", this.$route.query.id);
            // Cargar un user
            $api.getOneUser(this.$route.query.id, true).then((response) => {
                this.user = response;
                //console.log("USER: ", this.user);
                this.locale_name = this.user.language == 'en' ? 'English' : 'Español'
                this.locale_img = require('@/assets/locale/' + this.user.language + '.svg')
                this.loading = false;
                this.its_me = false
            }).catch((err) => {
                console.log(`[-] Error (getOneUser): ${err}`);
            });
        } else {
            // get asset_groups from API
            if (process.env.VUE_APP_ENV === 'development') {} else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getMe(true).then((response) => {
                    this.user = response;
                    //console.log("ME: ", this.user);
                    this.locale_name = this.user.language == 'en' ? 'English' : 'Español'
                    this.locale_img = require('@/assets/locale/' + this.user.language + '.svg')
                    this.loading = false;
                    this.its_me = true;
                }).catch((err) => {
                    console.log(`[-] Error (getMe): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }
        }


    },
};
export default MyShared;