import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import Tactic from "../Partials/Mitre/Tactic.vue";
import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Tasks",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": [],
            },
            mitre_matrix: [],
            loading: false,
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog,
        Tactic
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {

    },
    mounted() {
        this.loading = true;
        $api.getMitreMatrix().then((response) => {
            this.mitre_matrix = response;
            // Loop through the matrix and randomly add an attribute called 'alets' to each tactic
            this.mitre_matrix.forEach((tactic) => {
                tactic['alerts'] = Math.floor(Math.random() * 30);
                // Loop through the tactic.techniques and randomly add an attribute called 'alets' to each technique
                tactic.techniques.forEach((technique) => {
                    technique['alerts'] = Math.floor(Math.random() * 20);
                    // Loop through the technique.subtechniques and randomly add an attribute called 'alets' to each subtechnique
                    technique.subtechniques.forEach((subtechnique) => {
                        subtechnique['alerts'] = Math.floor(Math.random() * 10);
                    });
                });
            });
            this.loading = false;
        }).catch((err) => {
            console.log(`[-] Error (getOneEvent): ${err}`);
        });
    }
};
export default MyShared;