import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Tasks",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
    },
    mounted() {
        
    }
};
export default MyShared;