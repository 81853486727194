<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="card node-menu">
        <a class="node-menu-option" @click="viewObject()"><i class="pi pi-eye node-menu-option-icon"></i> View</a>
        <a class="node-menu-option"><i class="pi pi-trash node-menu-option-icon"></i> Remove</a>
    </div>
    <div class="arrow"></div>
</template>
  
  <!----------------------------------------------->
  <!--                    SCRIPT                 -->
  <!----------------------------------------------->
  
  <script scoped>
  import { nextTick } from "vue";
  import * as $api from "../../../api/api";
  export default {
    /* Name */
    name: "NameHere",
    /* Data */
    data() {
      return {
      };
    },
    /* Components */
    components: {},
    /* Props */
    props: {
        object:{},
        object_type: String,
    },
    
    /* Computed */
    computed: {

    },
    /* Methods */
    methods: {
        viewObject(){
            switch(this.object_type){
                case 'event':
                    this.$router.push({path: 'event-details', query: {id: this.object.id}});
                    break;
            }
        }
    },
    async mounted() {

    },
    /* Emits */
    emits: ["emits", "emits"],
  };
  </script>
  
  <!----------------------------------------------->
  <!--                    STYLES                 -->
  <!----------------------------------------------->
  <style scoped>
  .node-menu {
    width: 6em;
    height: auto;
    background-color: rgba(255, 255, 255, 0.616);
    z-index: 999;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.75em;
  }
  .node-menu-option {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }	
  .node-menu-option:hover {
    background-color: rgb(209, 209, 209);
    cursor: pointer;
  }	
  .node-menu-option-icon {
    font-size: smaller;
  }	
  </style>
  