import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import CustomChart from "../../components/Partials/CustomChart/CustomChart.vue"
import Modal from '../../components/Partials/Modals/Modal.vue'
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'

import Chart from 'primevue/chart';

import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "Listeners",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            activeListeners: 0,
            inactiveListeners: 0,
            editMode: false,
            uploadReady: false,
            uploadError: false,
            uploadLoading: false,
            showActions: true,
            actions: [{
                    label: this.$t('editMode'),
                    icon: 'pi pi-pencil',
                    callback: () => { this.changeToEditMode() },
                    permit: false
                },
                {
                    label: this.$t('import'),
                    icon: 'pi pi-upload',
                    callback: () => { this.openImportData() },
                    permit: false
                },
            ],
            items: [{
                    label: this.$t('details'),
                    step: 0
                },
                {
                    label: this.$t('detectionRules'),
                    step: 1
                },
                {
                    label: this.$t('correlationRules'),
                    step: 2
                },
                {
                    label: this.$t('assets'),
                    step: 3
                },
                {
                    label: this.$t('users'),
                    step: 4
                }
            ],
            statuses: [
                { label: this.$t('activated'), value: true },
                { label: this.$t('deactivated'), value: false },
            ],
            step: 0,
            tab_position: true,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                is_active: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            selectedData: null,
            atRiskAssets: 0,
            selectedGroups: [],
            hours: [
                this.$t('all_time'),
                this.$t('last_12_hours'),
                this.$t('last_24_hours'),
                this.$t('last_48_hours'),
                this.$t('last_7_days'),
                this.$t('last_1_month'),
            ],
            lastHours: this.$t('all_time'),
            data: [],
            data_backup: {},
            details: {
                "name": "",
                "description": "",
                "created_at": "",
                "id": "",
                "organization_id": "",
                "intake_schema": {},
                "intake_method": {},
            },
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
            groups: [],
            criticality_0: 0,
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            loading_table: false,
            loading_details: false,

            closedEvents: 0,
            inProgressEvents: 0,
            newEvents: 0,
            /* ==================================================================== Charts ==================================================================== */
            stackedData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    type: 'bar',
                    label: this.$t('new'),
                    backgroundColor: '#e8a8a8',
                    data: [50, 25, 12, 48, 90, 76, 42]
                }, {
                    type: 'bar',
                    label: this.$t('active'),
                    backgroundColor: '#d96e6e',
                    data: [21, 84, 24, 75, 37, 65, 34]
                }, {
                    type: 'bar',
                    label: this.$t('closed'),
                    backgroundColor: '#cf4545',
                    data: [41, 52, 24, 74, 23, 21, 32]
                }, {
                    type: 'bar',
                    label: this.$t('partiallyMitigated'),
                    backgroundColor: '#8b1717',
                    data: [41, 52, 24, 74, 23, 21, 32]
                }, {
                    type: 'bar',
                    label: this.$t('mitigated'),
                    backgroundColor: '#c2c2c2',
                    data: [41, 52, 24, 74, 23, 21, 32]
                }]
            },
            stackedOptions: {
                responsive: true,
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#fff'
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            lineChartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                        label: 'Critical',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        tension: .4,
                        borderColor: '#e8a8a8'
                    },
                    {
                        label: 'High',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        fill: false,
                        tension: .4,
                        borderColor: '#d96e6e'
                    },
                    {
                        label: 'Medium',
                        data: [12, 51, 62, 33, 21, 62, 45],
                        fill: false,
                        borderColor: '#cf4545',
                        tension: .4,
                        /*backgroundColor: 'rgba(255,167,38,0.2)'*/
                    },
                    {
                        label: 'Low',
                        data: [12, 80, 41, 60, 72, 10, 35],
                        fill: false,
                        borderColor: '#8b1717',
                        tension: .4,
                    },
                    {
                        label: 'Informational',
                        data: [30, 25, 40, 80, 52, 62, 13],
                        fill: false,
                        borderColor: '#c2c2c2',
                        tension: .4,
                    }
                ]
            },
            barData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                        type: 'line',
                        label: this.$t('new'),
                        borderColor: '#e8a8a8',
                        borderWidth: 2,
                        fill: false,
                        data: [50, 25, 12, 48, 56, 76, 42]
                    }, {
                        type: 'bar',
                        label: this.$t('active'),
                        backgroundColor: '#d96e6e',
                        data: [21, 84, 24, 75, 37, 65, 34],
                        borderColor: 'white',
                    }, {
                        type: 'bar',
                        label: this.$t('closed'),
                        backgroundColor: '#cf4545',
                        data: [41, 52, 24, 74, 23, 21, 32]
                    },
                    {
                        type: 'bar',
                        label: this.$t('closed'),
                        backgroundColor: '#8b1717',
                        data: [30, 25, 30, 8, 91, 21, 46]
                    },
                    {
                        type: 'bar',
                        label: this.$t('closed'),
                        backgroundColor: '#c2c2c2',
                        data: [41, 52, 24, 74, 23, 21, 32]
                    }
                ]
            },
            lineChartOptions: {
                responsive: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },

        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart,
        CustomChart,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        openImportData() {
            this.$refs.importData.openModal();
        },
        onUploadOne() {
            
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        onUploadVarious() {
            
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        theFormat(number) {
            return number.toFixed(2);
        },
        setSelectedData(data) {
            this.loading_details = true;
            this.loading_details = false;
            this.details = data;
        },
        setLastHours(hour) {
            this.loading_table = true;
            this.lastHours = hour;
            let sdate = new Date();
            let edate = new Date();

            switch (this.lastHours) {
                case this.$t('last_12_hours'):
                    sdate.setHours(sdate.getHours() - 12);
                    break;
                case this.$t('last_24_hours'):
                    sdate.setHours(sdate.getHours() - 24);
                    break;
                case this.$t('last_48_hours'):
                    sdate.setHours(sdate.getHours() - 48);
                    break;
                case this.$t('last_7_days'):
                    sdate.setDate(sdate.getDate() - 7);
                    break;
                case this.$t('last_1_month'):
                    sdate.setMonth(sdate.getMonth() - 1);
                    break;
                case this.$t('all_time'):
                    sdate.setFullYear(sdate.getFullYear() - 10);
                    break;
            }

            // Format dates to yyyy-mm-ddThh:mm:ss
            sdate = sdate.toISOString().split('.')[0];
            edate = edate.toISOString().split('.')[0];

            $api.getAllAssets(true, sdate).then((response) => {
                this.data = response;
                if (this.data) {
                    this.details = this.data[0];
                    this.calcPercentageByCriticality();
                    this.createChartsData();
                }

                this.loading_table = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssets): ${err}`);
            });
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        getActiveListeners() {
            this.activeListeners = 0;
            this.inactiveListeners = 0;
            this.data.forEach((item) => {
                if (item.is_active) {
                    this.activeListeners++;
                } else {
                    this.inactiveListeners++;
                }
            });
        },
        createData() {
            this.$refs.createData.openModal();
        },
        changeToEditMode() {
            this.editMode = true;
            this.selectedData = null
        },
        deleteData() {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateStatus(id, status) {
            // Add new status to the incident
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    let new_status = {
                        "status": status.status,
                        "description": status.description,
                    }
                    data[i].status.push(new_status);
                    $api.updateEvent(id, { "status": new_status }).then((response) => {
                        //console.log(`[+] Event ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('eventUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateEvent): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('eventNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            this.getInProgressEvents();
            this.getResolvedEvents();
            this.getNewEvents();
        },
        updateIsActive(id, is_active) {
            let data = this.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].is_active = is_active;
                    $api.updateListener(id, { "is_active": is_active }).then((response) => {
                        //console.log(`[+] Correlation ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('correlationUpdated'), life: 3000 });
                        this.getActiveListeners();
                    }).catch((err) => {
                        console.log(`[-] Error (updateCorrelation): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('correlationNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
        },
        pause(id) {
            this.$confirm.require({
                message: this.$t('pauseConfirmation'),
                header: this.$t('confirmation'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.updateIsActive(id, false);
                },
                reject: () => {},
            });
        },
        play(id) {
            this.$confirm.require({
                message: this.$t('playConfirmation'),
                header: this.$t('confirmation'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.updateIsActive(id, true);
                },
                reject: () => {},
            });
        },
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // set actions permissions
        this.actions[0].permit = this.hasUserPermissionGlobal(this.user, 'listener:update')
        this.actions[1].permit = this.hasUserPermissionGlobal(this.user, 'listener:create')
            // Loop actions, if all are false, then hide actions button
        let allFalse = true;
        for (let i = 0; i < this.actions.length; i++) {
            if (this.actions[i].permit) {
                allFalse = false;
                break;
            }
        }
        if (allFalse) {
            this.showActions = false;
        }

        // get events from API
        if (process.env.VUE_APP_ENV === 'development') {
            await (this.data = require('./events.json'));
            await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllListeners(true).then((response) => {
                this.data = response;
                this.data_backup = response;
                //console.log("LISTENERS: ", this.data);

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                if (this.data)
                    this.details = this.data[0];
                else
                    this.details = {};
                this.loading_table = false;

                this.lastHours = this.hours[0];

                this.getActiveListeners();

            }).catch((err) => {
                console.log(`[-] Error (getAllListeners): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    }
};
export default MyShared;