<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="mitre-container">
        <div class="mitre-tab pl-2 pr-2 pt-1 pb-4">
        <div class="p-0 m-0" v-if="loading">
          <ContentLoader style="height: 500px;"></ContentLoader>
        </div>
        <div class="mitre-matrix" v-else> 
          <Tactic :tactic="tactic" v-for="tactic in mitre_matrix" :key="tactic.id"></Tactic>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { random } from "gsap";
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.mitre-tab{
  overflow: auto;
  height: 100%;
}
.mitre-container{
  height: 84vh;
}
</style>
