<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <div class="maincontainer" style="padding-bottom:4em; padding-right: 50px;">
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Canvas ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row maximized" style="width: -moz-available;">
        <h3 class="col-12 p-0 m-3">{{dashboardName}}</h3>
        <div class="col-12 pt-0 pb-0">
          <div class="dashboard-canvas-no-edit">
            <smart-widget-grid :layout="layout" :verticalCompact="false" @layout-updated="updateLayout" :colNum="18" :isStatic="true">
              <!-- layout[$].i as slot name -->
              <template v-for="slot in layout" v-slot:[slot.i]="slotProps">
                <!-- ======================================================================= Visualization ======================================================================= -->
                <smart-widget simple :resized="/*refreshChart(slot.i)*/ null" style="height: inherit; width: auto;">
                  <div class="layout-center" :id="slot.i">
                    <h5 class="mb-0">{{ slot.name }}</h5>
                    <p class="mt-0 chart-desc">{{ slot.desc }}</p>
                    <!-- Loading auxs -->
                    <div class="loading-aux" v-if="slot.typeof == 'line' || slot.typeof == 'bar' || slot.typeof == 'pie'" >
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.chartData">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <div class="loading-aux" v-if="slot.typeof == 'number'" >
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.number">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <div class="loading-aux" v-if="slot.typeof == 'text'" >
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.text">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <!-- Chart -->
                    <Chart v-if="slot.typeof == 'line' && slot.chartData" type="line" :data="slot.chartData"
                      :options="lineChartOptions" />
                    <Chart v-if="slot.typeof == 'bar' && slot.chartData" type="bar" :data="slot.chartData"
                      :options="lineChartOptions" />
                    <Chart v-if="slot.typeof == 'pie' && slot.chartData" type="pie" :data="slot.chartData"
                      :options="pieChartOptions" />
                    <div class="text-chart" v-if="slot.typeof == 'text'">
                      <h6>{{ slot.text }}</h6>
                    </div>
                    <div class="number-chart" v-if="slot.typeof == 'number'">
                      <number style="font-size: 4em; font-weight:700;" ref="users_number" :from="0" :to="slot.number"
                        :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat" />
                      <h3 class="ml-2" style="display:inline-block">{{ slot.unit }}</h3>
                    </div>
                  </div>
                  <div class="vue-resizable-handle"></div>
                </smart-widget>
                <!-- ==================================================================================================================================================== -->
              </template>
            </smart-widget-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.chart-title{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.dashboard-canvas-no-edit {
  background: #ffffff;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.5em;
}
.vue-grid-item {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  background: #ffffff !important;
  z-index: 2 !important;
}
.maximized {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: white;
  margin: 0;
  overflow: auto;
}
</style>
