import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import CustomChart from "../../components/Partials/CustomChart/CustomChart.vue"
import Chart from 'primevue/chart';
import Modal from '../../components/Partials/Modals/Modal.vue'
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'

import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            editMode: false,
            actives: 0,
            uploadReady: false,
            uploadError: false,
            uploadLoading: false,
            showActions: true,
            actions: [{
                    label: this.$t('newCorrelationRule'),
                    icon: 'pi pi-plus',
                    callback: () => { this.createData() },
                    permit: false
                },
                {
                    label: this.$t('editMode'),
                    icon: 'pi pi-pencil',
                    callback: () => { this.changeToEditMode() },
                    permit: false
                },
                {
                    label: this.$t('import'),
                    icon: 'pi pi-upload',
                    callback: () => { this.openImportData() },
                    permit: false
                },
            ],
            items: [{
                    label: this.$t('details'),
                    step: 0
                },
                {
                    label: this.$t('source'),
                    step: 1
                }
            ],
            new_selectedSources: [],
            sources: [],
            step: 0,
            viewmode: 0,
            viewmodemenu: false,
            tab_position: true,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                os: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                host: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                type: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                criticality: {
                    operator: FilterOperator.OR,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                last_act_date: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
            statuses: [
                { label: this.$t('activated'), value: true },
                { label: this.$t('deactivated'), value: false },
            ],
            selectedData: null,
            atRiskAssets: 0,
            selectedGroups: [],
            hours: [
                this.$t('all_time'),
                this.$t('last_12_hours'),
                this.$t('last_24_hours'),
                this.$t('last_48_hours'),
                this.$t('last_7_days'),
                this.$t('last_1_month'),
            ],
            lastHours: this.$t('all_time'),
            data: [],
            data_backup: {},
            details: {
                "id": 0,
                "description": '',
                "name": '',
                "os": '',
                "host": '',
                "type": '',
                "location": {
                    "country": '',
                    "city": '',
                    "latitude": 0,
                    "longitude": 0
                },
                "criticality": '',
                "tags": [],
                "ip_addr": [],
                "mac_addr": [],
                "first_date": '',
                "last_date": '',
                "asset_groups": [{
                    "description": "",
                    "name": "",
                    "organization_id": "",
                    "tags": [],
                    "id": ""
                }],
                "asset_events": [{
                    "title": "",
                    "description": "",
                    "severity": 0,
                    "detection_rule_id": "",
                    "created_at": "",
                    "status": [{
                        "status": "",
                        "description": "",
                        "user": "",
                        "date": ""
                    }],
                    "id": "",
                    "organization_id": ""
                }],
                "asset_incidents": [{
                    "title": "",
                    "description": "",
                    "severity": 1,
                    "status": [{
                        "status": "",
                        "description": ""
                    }],
                    "id": "",
                    "team_id": "",
                    "correlation_rule_id": "",
                    "events": [
                        ""
                    ]
                }]
            },
            groups: [],
            criticality_0: 0,
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_info_criticality: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            loading_table: false,
            loading_details: false,
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        CustomChart,
        Chart,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        openImportData() {
            this.$refs.importData.openModal();
        },
        onUploadOne() {
            
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        onUploadVarious() {
            
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        theFormat(number) {
            return number.toFixed(2);
        },
        setSelectedData(data) {
            this.loading_details = true;
            this.loading_details = false;
            this.details = data;
        },
        setLastHours(hour) {
            this.loading_table = true;
            this.lastHours = hour;
            let sdate = new Date();
            let edate = new Date();

            switch (this.lastHours) {
                case this.$t('last_12_hours'):
                    sdate.setHours(sdate.getHours() - 12);
                    break;
                case this.$t('last_24_hours'):
                    sdate.setHours(sdate.getHours() - 24);
                    break;
                case this.$t('last_48_hours'):
                    sdate.setHours(sdate.getHours() - 48);
                    break;
                case this.$t('last_7_days'):
                    sdate.setDate(sdate.getDate() - 7);
                    break;
                case this.$t('last_1_month'):
                    sdate.setMonth(sdate.getMonth() - 1);
                    break;
                case this.$t('all_time'):
                    sdate.setFullYear(sdate.getFullYear() - 10);
                    break;
            }

            // Format dates to yyyy-mm-ddThh:mm:ss
            sdate = sdate.toISOString().split('.')[0];
            edate = edate.toISOString().split('.')[0];

            $api.getAllCorrelationRules(true, sdate).then((response) => {
                this.data = response;
                if (this.data) {
                    this.details = this.data[0];
                    this.calcPercentageByCriticality();
                    //this.createChartsData();
                }

                this.loading_table = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssets): ${err}`);
            });
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        getAtRiskAssets() {
            // Find all assets with risk == true
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].risk == true) {
                    data_filtered.push(data[i]);
                }
            }
            this.atRiskAssets = data_filtered.length
        },
        calcPercentageByCriticality() {
            // Calculate the percentage of each criticality
            let criticality_0 = 0;
            let criticality_1 = 0;
            let criticality_2 = 0;
            let criticality_3 = 0;
            let criticality_4 = 0;
            let total = 0;
            this.data.forEach(element => {
                switch (element.severity) {
                    case 0:
                        criticality_0++;
                        break;
                    case 1:
                        criticality_1++;
                        break;
                    case 2:
                        criticality_2++;
                        break;
                    case 3:
                        criticality_3++;
                        break;
                    case 4:
                        criticality_4++;
                        break;
                }
                total++;
            });

            total = criticality_0 + criticality_1 + criticality_2 + criticality_3 + criticality_4;
            // Set the percentage of each criticality
            this.criticality_0 = (criticality_0 / total) * 100
            this.criticality_1 = (criticality_1 / total) * 100
            this.criticality_2 = (criticality_2 / total) * 100
            this.criticality_3 = (criticality_3 / total) * 100
            this.criticality_4 = (criticality_4 / total) * 100

            // Prepare for the chart
            this.criticality_0 = this.criticality_0 - 0.8;
            this.criticality_1 = this.criticality_1 - 0.8;
            this.criticality_2 = this.criticality_2 - 0.8;
            this.criticality_3 = this.criticality_3 - 0.8;
            this.criticality_4 = this.criticality_4 - 0.8;

            // Number of elements 
            this.number_of_info_criticality = criticality_0;
            this.number_of_low_criticality = criticality_1;
            this.number_of_medium_criticality = criticality_2;
            this.number_of_high_criticality = criticality_3;
            this.number_of_critical_criticality = criticality_4;

            this.actives = this.getIsActives();
        },
        createData() {
            //get all events from api
            $api.getAllSources(false).then(response => {
                this.sources = response;
            }).catch(error => {
                console.log(error);
            });

            this.$refs.createData.openModal();
        },
        changeToEditMode() {
            this.editMode = true;
            this.selectedData = null
        },
        deleteData() {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.deleteCorrelationRule(this.selectedData[i].id).then((response) => {
                            //console.log(`[+] Correlation ${this.selectedData[i].name} deleted`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('recordDeleted'), life: 3000 });
                            //refresh 
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (deleteCorrelation): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('recordNotDeleted'), life: 3000 });
                        });
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        setCriticalityForSelectedItems(criticality){
            this.$confirm.require({
                message: this.$t('confirmDialog'),
                header: this.$t('confirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.updateCorrelationRule(this.selectedData[i].id, { "severity": criticality }).then((response) => {
                            //console.log(`[+] Asset ${this.selectedData[i].id} updated`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                            //refresh
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (updateAsset): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
                        });;
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateSeverity(id, severity) {
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].severity = severity;
                    $api.updateCorrelationRule(id, { "severity": severity }).then((response) => {
                        //console.log(`[+] Correlation ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('correlationUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateCorrelation): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('correlationNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            this.calcPercentageByCriticality();
        },
        updateIsActive(id, is_active) {
            let data = this.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].is_active = is_active;
                    $api.updateCorrelationRule(id, { "is_active": is_active }).then((response) => {
                        //console.log(`[+] Correlation ${id} updated`);
                        this.actives = this.getIsActives();
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('correlationUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateCorrelation): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('correlationNotUpdated'), life: 3000 });
                    });;
                }
            }

            this.data = data;
        },
        getIsActives() {
            let is_actives = 0;
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].is_active == true) {
                    is_actives++
                }
            }
            return is_actives;
        },
        createArrayOfIds(array) {
            let ids = [];
            for (let i = 0; i < array.length; i++) {
                ids.push(array[i].id);
            }
            return ids;
        },
        addNewCorrelation(new_title, new_desc, new_author) {
            let new_correlation = {
                "title": new_title,
                "description": new_desc,
                "severity": 0,
                "is_active": true,
                "author": new_author,
                "sources": this.createArrayOfIds(this.new_selectedSources),
                "configuration": {}
            }
            $api.createCorrelationRule(new_correlation).then((response) => {
                //console.log(`[+] Correlation created`);
                this.data.push(response);
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('correlationCreated'), life: 3000 });
                this.$refs.createData.closeModal();
            }).catch((err) => {
                console.log(`[-] Error (createCorrelationRule): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: err.response.data.detail, life: 3000 });
            });
        }

    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // set actions permissions
        this.actions[0].permit = this.hasUserPermissionGlobal(this.user, 'correlation_rule:create')
        this.actions[1].permit = this.hasUserPermissionGlobal(this.user, 'correlation-rule:update')
        this.actions[2].permit = this.hasUserPermissionGlobal(this.user, 'correlation_rule:create')
            // Loop actions, if all are false, then hide actions button
        let allFalse = true;
        for (let i = 0; i < this.actions.length; i++) {
            if (this.actions[i].permit) {
                allFalse = false;
                break;
            }
        }
        if (allFalse) {
            this.showActions = false;
        }

        // get correlation rules from API
        if (process.env.VUE_APP_ENV === 'development') {
            await (this.data = require('./alerts.json'));
            await (this.data_backup = require('./alerts.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllCorrelationRules(true).then((response) => {
                this.data = response;
                this.data_backup = response;

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                if (this.data)
                    this.details = this.data[0];
                this.loading_table = false;

                this.lastHours = this.hours[0];
                this.calcPercentageByCriticality();

            }).catch((err) => {
                console.log(`[-] Error (getAllCorrelationRules): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    }
};
export default MyShared;