<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="mitre-tactic">
        <div class="tactic-name-div" v-tooltip.top="tactic.name" @click="open=!open">
            <p class="tactic-name" >
                <span @click="openRoute(tactic.url)">{{ wrapString(tactic.name,20) }}&nbsp;</span>
                <span class="alerts-in-company critical" v-if="tactic.alerts>15">{{tactic.alerts}}</span>
                <span class="alerts-in-company high" v-else-if="tactic.alerts>10">{{tactic.alerts}}</span>
                <span class="alerts-in-company medium" v-else-if="tactic.alerts>5">{{tactic.alerts}}</span>
                <span class="alerts-in-company low" v-else-if="tactic.alerts>2">{{tactic.alerts}}</span>
                <span class="alerts-in-company info" v-else-if="tactic.alerts>0">{{tactic.alerts}}</span>
                <span class="technique-open m-0" v-if="tactic.techniques.length>0"> <v-icon :name="open ? 'bi-chevron-up':'bi-chevron-down'"  scale="0.9"></v-icon></span>
            </p>
            <p class="technique-count" v-if="tactic.techniques.length>0">{{tactic.techniques.length + ' ' + $t('techniques')}} </p>
        </div>
        
        <span class="techniques" v-if="tactic.techniques && open">
            <Technique v-for="technique in tactic.techniques" :key="technique.id" :technique="technique"></Technique>
        </span>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import Technique from "./Technique.vue";
export default {
    name: "Tactic",
    data() {
        return {
            open: true
        };
    },
    components: {
        Technique
    },
    methods: {
        wrapString(str, maxLen) {
            if (str.length > maxLen) {
                return str.substring(0, maxLen) + "...";
            }
            return str;
        },
        openRoute(url){
            // Open url on new window
            window.open(url,'_blank');
        }
    },
    props: {
        tactic: Object,
    },
    mounted(){
        console.log(this.tactic);
        
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.mitre-tactic {
    padding: 0rem;
    display: inline-block;
    margin-right: 0.25em;
    min-width: 15rem;
}
.tactic-name-div{
    border: #cecece 1px solid;
    border-bottom: #222222 2px solid;
    color: 222222;
    padding: 0.75rem;
    font-size: 0.9rem;
    font-weight: bold;
}
.tactic-name:hover{
    cursor: pointer;
    text-decoration: underline;
}
.alerts-in-company{
    border-radius: 50%;
    font-size: smaller;
    padding: 0.13rem;
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    text-align: center;
    margin-right: 0.25rem;
}
.critical{
    background-color: #b83c32;
    color: white;
}
.high{
    background-color: #ef6154;
    color: white;
}
.medium{
    background-color: #f0ad4e;
    color: white;
}
.low{
    background-color: #5cb85c;
    color: white;
}
.info{
    background-color: #aeb5c1;
    color: white;
}
.technique-count{
    font-size: smaller;
    color: #9c9c9c;
}
.technique-open{
    float: right;
    margin-right: 0.5rem;
}
.technique-open{
    cursor: pointer;
}

</style>