import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue";
import Modal from "../../components/Partials/Modals/Modal.vue";
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";

import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            deleteAll: false,
            typeToConfirm: '',
            checkType: 'I am sure that I want to delete everything',
            data: {
                id: '',
                name: ' ',
                logo: '',
            },
            loading: true,
            languages: [
                { flag: require('@/assets/locale/es.svg'), language: 'es', title: 'Español' },
                { flag: require('@/assets/locale/en.svg'), language: 'en', title: 'English' },

            ],
            locale_img: require('@/assets/locale/en.svg'),
            locale_name: 'English',
            edit_full_name: false,
            edit_email: false,
            edit_desc: false,
            edit_phone: false,
            edit_gender: false,
            edit_pass: false,
            old_pass: '',
            new_pass: '',
            new_confirm_pass: '',
            show_pass_error: false,
            pass_error: '',
            uploadedFiles: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        /* ====================================== Picture ====================================== */
        async onAdvancedUpload(event) {
            let files = event.files[0]

            let data = new FormData()
            data.append('in_file', files)

            this.updatePicture(data)
        },
        updatePicture(result) {
            $api.newLogo(this.data.id, result).then(
                response => {
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                    $api.getOneOrganization(this.user.organization.id).then((response) => {
                        this.data = response;
                        //console.log("ORGANIZATION: ", this.data);
                        this.loading = false;
                    }).catch((err) => {
                        console.log(`[-] Error (getOneOrganization): ${err}`);
                    });
                }
            ).catch((err) => {
                console.log(`[-] Error (updateMe): ${err}`);
            })
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        /* ==================================================================================== */
        updateName() {
            $api.updateOrganization(this.data.id, { "name": this.data.name }).then(
                response => {
                    this.edit_full_name = false
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                }
            ).catch((err) => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                console.log(`[-] Error (updateOrganization): ${err}`);
            })
        },
        updateDescription() {
            $api.updateOrganization(this.data.id, { "info": this.data.info }).then(
                response => {
                    this.edit_desc = false
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('userUpdated'), life: 3000 });
                }
            ).catch((err) => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                console.log(`[-] Error (updateOrganization): ${err}`);
            })
        },
        deleteAllData() {
            // check if the user has confirmed the type
            if (this.typeToConfirm === this.checkType) {

                // delete all sources
                $api.deleteAllSources().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('sources') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('sources') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllSources): ${err}`);
                })

                // delete all listeners
                $api.deleteAllListeners().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('listeners') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('listeners') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllListeners): ${err}`);
                })

                // delete all logs
                $api.deleteAllLogs().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('logs') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('logs') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllLogs): ${err}`);
                })

                // delete all assets
                $api.deleteAllAssets().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assets') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assets') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllAssets): ${err}`);
                })

                // delete all asset_groups
                $api.deleteAllAssetGroups().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetGroups') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetGroups') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllAssetGroups): ${err}`);
                })

                // delete all dashboards
                $api.deleteAllDashboards().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('dashboards') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('dashboards') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllDashboards): ${err}`);
                })

                // delete all visualizations
                $api.deleteAllVisualizations().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('visualizations') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('visualizations') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllVisualizations): ${err}`);
                })

                // delete all visualizationDashboards
                $api.deleteAllVisualizationDashboards().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('visualizationDashboards') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('visualizationDashboards') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllVisualizationDashboards): ${err}`);
                })

                // delete all events
                $api.deleteAllEvents().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('events') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('events') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllEvents): ${err}`);
                })

                // delete all incidents
                $api.deleteAllIncidents().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidents') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidents') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllIncidents): ${err}`);
                })

                // delete all alerts
                $api.deleteAllAlerts().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('alerts') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('alerts') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllAlerts): ${err}`);
                })

                // delete all correlationRules
                $api.deleteAllCorrelationRules().then(
                    response => {
                        // delete all detectionRules
                        $api.deleteAllDetectionRules().then(
                            response => {
                                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('detectionRules') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                            }).catch((err) => {
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('detectionRules') + ' ' + this.$t('notDeletedSuccesfully') });
                            console.log(`[-] Error (deleteAllDetectionRules): ${err}`);
                        })
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('correlationRules') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('correlationRules') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllCorrelationRules): ${err}`);
                })



                // delete all roles
                $api.deleteAllRoles().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('roles') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('roles') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllRoles): ${err}`);
                })

                // delete all teams
                $api.deleteAllTeams().then(
                    response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('teams') + ' ' + this.$t('deletedSuccesfully'), life: 3000 });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('teams') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (deleteAllTeams): ${err}`);
                })

                // get all users
                $api.getAllUsers().then(
                    response => {
                        // delete all users 
                        response.forEach(user => {
                            if (user.full_name != "admin") {
                                $api.deleteUser(user.id).then(
                                    response => {}).catch((err) => {
                                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('users') + ' ' + this.$t('deletedSuccesfully') });
                                    console.log(`[-] Error (deleteUser): ${err}`);
                                })
                            }
                        });
                    }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('users') + ' ' + this.$t('notDeletedSuccesfully') });
                    console.log(`[-] Error (getAllUsers): ${err}`);
                })

            } else {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('noMatch'), life: 3000 });
            }

        },

    },
    mounted() {
        if (process.env.VUE_APP_ENV === 'development') {} else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            $api.getMe(true).then((response) => {
                this.user = response;
                //console.log("ME: ", this.user);
                $api.getOneOrganization(this.user.organization.id).then((response) => {
                    this.data = response;
                    //console.log("ORGANIZATION: ", this.data);
                    this.loading = false;
                }).catch((err) => {
                    console.log(`[-] Error (getOneOrganization): ${err}`);
                });
            }).catch((err) => {
                console.log(`[-] Error (getMe): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

    },
};
export default MyShared;