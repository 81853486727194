<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileCampaigns v-if="  mq.current  === 'mobile'"></MobileCampaigns>
  <TabletCampaigns v-if="  mq.current  === 'tablet'"></TabletCampaigns>
  <DesktopCampaigns v-if=" mq.current  === 'desktop'"></DesktopCampaigns>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileCampaigns from "../components/Campaigns/MobileCampaigns";
import TabletCampaigns from "../components/Campaigns/TabletCampaigns";
import DesktopCampaigns from "../components/Campaigns/DesktopCampaigns";

export default {
  /* Name */
  name: "Campaigns",
  inject: ["mq"],
  /* Components */
  components: {
    MobileCampaigns,
    TabletCampaigns,
    DesktopCampaigns,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
