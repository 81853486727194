import 'bootstrap-css-only/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap';

import $ from "jquery";

import PrimeVue from 'primevue/config';

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import TerminalService from 'primevue/terminalservice'
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import VirtualScroller from 'primevue/virtualscroller';

window.$ = $
export const app = createApp(App)


/* ------ Config ------ */
var CONFIG = require('./config_themes.json');
//get actual year
var currentYear = new Date().getFullYear();
app.config.globalProperties.footerCopyRight = "Copyright " + currentYear + " | AIRsiem";
app.config.globalProperties.projectName = CONFIG.projectName
app.config.globalProperties.projectSubtitle = CONFIG.projectSubtitle
app.config.globalProperties.base_url = process.env.BASE_URL // CONFIG.api_url
app.config.globalProperties.projectLogo = require('@/assets/logo.svg');
/* ------ End of config ------ */


/* =================================== String Formatting ===================================== */
app.config.globalProperties.formatString = (text, max_chars = 30) => {
    // if str.length > 30 then return str.substring(0, 30) + "..."
    if (text.length > max_chars) {
        return text.substring(0, max_chars) + "..."
    }
    return text
}

app.config.globalProperties.prettifyString = (text) => {
    // Capitalize first letter 
    let final = text.charAt(0).toUpperCase() + text.slice(1);
    // Replace _ with space
    final = final.replace(/_/g, " ");
    return final
}

/* ------------------------------------------------------------------------------------------ */



/* ===================================== Save Dashboard ===================================== */
app.config.globalProperties.dashboardSaved = false

app.config.globalProperties.setDashboardSaved = () => {
    //console.log("Dashboard has been saved successfully!");
    app.config.globalProperties.dashboardSaved = true
}
app.config.globalProperties.getDashboardSaved = () => {
    return app.config.globalProperties.dashboardSaved
}
app.config.globalProperties.resetDashboardSaved = () => {
    app.config.globalProperties.dashboardSaved = false
}

/* ------------------------------------------------------------------------------------------ */



/* ====================================== Permissions ======================================= */

app.config.globalProperties.hasUserPermissionGlobal = (user, permission) => {
    // Check if is superuser
    if (user.is_superuser) {
        console.log("[+] User is superuser!");
        return true;
    }

    console.log('---------------------------------');
    console.log("Checking if user: ");
    console.log(user);
    console.log("has permission: " + permission);

    // Loop through user.permissions
    // Check if user.permissions[i].value is equal to permission
    for (let i = 0; i < user.permissions.length; i++) {
        if (user.permissions[i].value === permission) {
            console.log("[+] User has permission!");
            console.log('---------------------------------');
            return true;
        }
    }
    // If it is not, return false
    console.log("[-] User does NOT have permission!");
    console.log('---------------------------------');
    return false;
}


/* ====================================== Localization ====================================== */
import i18n from '@/plugins/i18n.js'
app.use(i18n);

app.config.globalProperties.setGlobalLocale = (locale) => {
    i18n.global.locale = locale
}
app.config.globalProperties.getGlobalLocale = () => {
    return i18n.global.locale
}

/* ------------------------------------------------------------------------------------------ */



/* ======================================= User store ======================================= */
import { createPinia } from 'pinia';
import { useUserStore } from "./store/store";
app.use(createPinia())
const userStore = useUserStore();
/* ------------------------------------------------------------------------------------------ */



/* ===================================== Content loader ===================================== */
import { ContentLoader } from 'vue-content-loader'
app.component('ContentLoader', ContentLoader);
/* ------------------------------------------------------------------------------------------ */



/* ======================================= Vue Number ======================================= */
import VueNumber from 'vue-number-animation'
app.use(VueNumber);
/* ------------------------------------------------------------------------------------------ */



/* ======================================= Vue Cookies ======================================= */
import VueCookies from 'vue-cookies'
app.use(VueCookies, {
    expireTimes: process.env.ACCESS_TOKEN_EXPIRE_TIME,
    // secure: true,
    sameSite: 'Strict'
});
/* ------------------------------------------------------------------------------------------ */



/* ========================================= Router ========================================= */
import router from './router'
app.use(router);
/* ------------------------------------------------------------------------------------------ */



/* ======================================= Smart Widget ===================================== */
import VueSmartWidget from 'vue-smart-widget'
app.use(VueSmartWidget);
/* ------------------------------------------------------------------------------------------ */



/* ==================================== Vue Mq (responsive) ================================= */
import { Vue3Mq } from "vue3-mq";
app.use(Vue3Mq, {
    breakpoints: { mobile: 0, tablet: 500, desktop: 1200 }
});
/* ------------------------------------------------------------------------------------------ */

/* ======================================== Tooltip ========================================= */
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

app.use(Vue3VideoPlayer, {
    lang: 'en'
});

/* ------------------------------------------------------------------------------------------ */

/* ======================================== Tooltip ========================================= */
app.directive("tooltip", Tooltip);
/* ------------------------------------------------------------------------------------------ */


/* ============================= Maz-UI (used for phone input) ============================== */
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

app.component('MazPhoneNumberInput', MazPhoneNumberInput)
    /* ------------------------------------------------------------------------------------------ *

    /* ======================================= PrimeVue ======================================= */
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(TerminalService);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Chart ', Chart);
app.component('Carousel', Carousel);
app.component('CascadeSelect', CascadeSelect);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('DeferredContent', DeferredContent);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dock', Dock);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Row', Row);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Terminal', Terminal);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('VirtualScroller', VirtualScroller);
/* ------------------------------------------------------------------------------------------ */

/* ======================================= Oh, Vue Icons! ======================================== */
import { OhVueIcon, addIcons } from "oh-vue-icons";
import * as MdIcons from "oh-vue-icons/icons/md";
import * as BiIcons from "oh-vue-icons/icons/bi";

const Md = Object.values({ ...MdIcons });
const Bi = Object.values({ ...BiIcons });
addIcons(...Md);
addIcons(...Bi);

app.component("v-icon", OhVueIcon);
/* ------------------------------------------------------------------------------------------ */

const vm = app.mount('#app')