<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileMitre v-if="  mq.current  === 'mobile'"></MobileMitre>
  <TabletMitre v-if="  mq.current  === 'tablet'"></TabletMitre>
  <DesktopMitre v-if=" mq.current  === 'desktop'"></DesktopMitre>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileMitre from "../components/Mitre/MobileMitre";
import TabletMitre from "../components/Mitre/TabletMitre";
import DesktopMitre from "../components/Mitre/DesktopMitre";

export default {
  /* Name */
  name: "Mitre",
  inject: ["mq"],
  /* Components */
  components: {
    MobileMitre,
    TabletMitre,
    DesktopMitre,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
