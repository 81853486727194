import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import CustomChart from "../../components/Partials/CustomChart/CustomChart.vue"
import Modal from '../../components/Partials/Modals/Modal.vue'
import ConfirmDialog from "primevue/confirmdialog";
import { nextTick } from "vue";
import Chart from 'primevue/chart';
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'


var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            editMode: false,
            new_correlationRule: null,
            new_team: null,
            new_selectedEvents: [],
            new_selectedAssets: [],
            uploadReady: false,
            uploadError: false,
            uploadLoading: false,
            showActions: true,
            actions: [{
                    label: this.$t('newIncident'),
                    icon: 'pi pi-plus',
                    callback: () => { this.createData() },
                    permit: false
                },
                {
                    label: this.$t('editMode'),
                    icon: 'pi pi-pencil',
                    callback: () => { this.changeToEditMode() },
                    permit: false
                },
                {
                    label: this.$t('import'),
                    icon: 'pi pi-upload',
                    callback: () => { this.openImportData() },
                    permit: false
                },
            ],
            items: [{
                    label: this.$t('details'),
                    step: 0
                },
                {
                    label: this.$t('events'),
                    step: 1
                },
                {
                    label: this.$t('assets'),
                    step: 2
                },
            ],
            step: 0,
            output: "",
            viewmode: 0,
            viewmodemenu: false,
            tab_position: true,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                title: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                os: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                host: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                type: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                criticality: {
                    operator: FilterOperator.OR,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                last_act_date: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            selectedData: null,
            atRiskAssets: 0,
            selectedGroups: [],

            hours: [
                this.$t('all_time'),
                this.$t('last_12_hours'),
                this.$t('last_24_hours'),
                this.$t('last_48_hours'),
                this.$t('last_7_days'),
                this.$t('last_1_month'),
            ],
            lastHours: this.$t('all_time'),
            data: [],
            data_backup: {},
            details: {
                "title": "",
                "description": "",
                "created_at": "",
                "status": [{
                    "status": "",
                    "description": "",
                    "user": ""
                }],
                "severity": 2,
                "id": "",
                "organization_id": "",
                "team": null,
                "events": [{
                    "created_at": "",
                    "status": [{
                        "status": "",
                        "description": "",
                        "user": ""
                    }],
                    "title": "",
                    "description": "",
                    "id": "",
                    "organization_id": "",
                    "severity": 2,
                    "detection_rule_id": ""
                }],
                "correlation_rule": {
                    "configuration": {},
                    "description": "",
                    "author": "",
                    "id": "",
                    "created_at": "",
                    "title": "",
                    "severity": 2,
                    "is_active": true,
                    "organization_id": ""
                },
                "assets": [{
                    "id": "",
                    "name": "",
                    "description": "",
                    "criticality": 4
                }]
            },
            teams: null,
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
            statuses: [
                { label: this.$t('new'), status: 'new', description: 'New incident detected' },
                { label: this.$t('active'), status: 'active', description: 'Incident is being investigated' },
                { label: this.$t('partiallyMitigated'), status: 'partial_mitigated', description: 'Incident is partially mitigated' },
                { label: this.$t('mitigated'), status: 'mitigated', description: 'Incident is mitigated' },
                { label: this.$t('closed'), status: 'closed', description: 'Incident is closed' }
            ],
            groups: [],
            criticality_0: 0,
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            loading_table: false,
            loading_details: false,

            inProgressIncidents: 0,
            closedIncidents: 0,
            newIncidents: 0,

            /* ==================================================================== Charts ==================================================================== */
            stackedData: {},
            lineChartData: {},
            stackedOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#fff'
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            barData: {},
            pieChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            boxWidth: 45,
                            color: '#495057'
                        }
                    }
                }
            },
            pieChartData1: {},
            pieChartData2: {},
            lineChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart,
        CustomChart,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        openImportData() {
            this.$refs.importData.openModal();
        },
        onUploadOne() {
            
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        onUploadVarious() {
            
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        setSelectedData(data) {
            this.loading_details = true;
            $api.getOneIncident(data.id, true).then((response) => {
                this.details = data;
                if (response.events.length > 0) {
                    this.details.events = response.events;
                }
                if (response.assets.length > 0) {
                    this.details.assets = response.assets;
                }
                this.loading_details = false;
            }).catch((err) => {
                console.log(`[-] Error (getOneAsset): ${err}`);
            });
        },
        setLastHours(hour) {
            this.loading_table = true;
            this.lastHours = hour;
            let sdate = new Date();
            let edate = new Date();

            switch (this.lastHours) {
                case this.$t('last_12_hours'):
                    sdate.setHours(sdate.getHours() - 12);
                    break;
                case this.$t('last_24_hours'):
                    sdate.setHours(sdate.getHours() - 24);
                    break;
                case this.$t('last_48_hours'):
                    sdate.setHours(sdate.getHours() - 48);
                    break;
                case this.$t('last_7_days'):
                    sdate.setDate(sdate.getDate() - 7);
                    break;
                case this.$t('last_1_month'):
                    sdate.setMonth(sdate.getMonth() - 1);
                    break;
                case this.$t('all_time'):
                    sdate.setFullYear(sdate.getFullYear() - 10);
                    break;
            }

            // Format dates to yyyy-mm-ddThh:mm:ss
            sdate = sdate.toISOString().split('.')[0];
            edate = edate.toISOString().split('.')[0];

            $api.getAllIncidents(true, sdate).then((response) => {
                this.data = response;
                if (this.data) {
                    this.details = this.data[0];
                    this.calcPercentageByCriticality();
                    this.createChartsData();
                    // Calculate in progress incidents
                    this.getInProgressIncidents();
                    // Calculate resolved incidents
                    this.getResolvedIncidents();
                    // Calculate new incidents
                    this.getNewIncidents();
                }

                this.loading_table = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssets): ${err}`);
            });
        },
        theFormat(number) {
            return number.toFixed(2);
        },
        formatDate(date) {
            if (date == null || date == '') return;
            let d = new Date(date);
            let day = d.toISOString().slice(0, 10);
            // get time 
            let time = d.toISOString().slice(11, 19);
            return day + ' ' + time;
        },
        loadGroups() {
            // Load groups from data 
            let groups = [];
            // loop through data
            this.data.forEach((item) => {
                // loop through asset_groups
                item.asset_groups.forEach((group) => {
                    // check if group is already in groups
                    // loop through groups
                    let found = false;
                    groups.forEach((g) => {
                        if (g.id == group.id) {
                            found = true;
                        }
                    });
                    if (!found) {
                        // if not, add it
                        groups.push({
                            'id': group.id,
                            'name': group.name
                        });
                    }
                });
            });
            // set groups
            this.groups = groups;
        },
        filterByGroup(group_ids, add) {
            // If no group is selected, show all assets
            if (group_ids.length == 0) {
                this.data = this.data_backup;
                this.calcPercentageByCriticality();
                return;
            }

            // Other case 
            // If is clicked on group tab
            if (add) {
                this.selectedGroups.push(group_ids[0]);
            }
            // Get all data assets from group
            let data = this.data_backup;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                // Check if asset is in group
                for (let j = 0; j < data[i].asset_groups.length; j++) {
                    for (let k = 0; k < group_ids.length; k++) {
                        if (data[i].asset_groups[j].id == group_ids[k].id) {
                            // Add asset to data_filtered if not already in it
                            let found = false;
                            for (let l = 0; l < data_filtered.length; l++) {
                                if (data_filtered[l].id == data[i].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                data_filtered.push(data[i]);
                            }
                        }
                    }
                }
            }
            this.data = data_filtered;
            this.calcPercentageByCriticality();
        },
        calcPercentageByCriticality() {
            // Calculate the percentage of each criticality
            let criticality_0 = 0;
            let criticality_1 = 0;
            let criticality_2 = 0;
            let criticality_3 = 0;
            let criticality_4 = 0;
            let total = 0;
            this.data.forEach(element => {
                switch (element.severity) {
                    case 0:
                        criticality_0++;
                        break;
                    case 1:
                        criticality_1++;
                        break;
                    case 2:
                        criticality_2++;
                        break;
                    case 3:
                        criticality_3++;
                        break;
                    case 4:
                        criticality_4++;
                        break;
                }
                total++;
            });

            total = criticality_0 + criticality_1 + criticality_2 + criticality_3 + criticality_4;
            // Set the percentage of each criticality
            this.criticality_0 = (criticality_0 / total) * 100
            this.criticality_1 = (criticality_1 / total) * 100
            this.criticality_2 = (criticality_2 / total) * 100
            this.criticality_3 = (criticality_3 / total) * 100
            this.criticality_4 = (criticality_4 / total) * 100

            // Prepare for the chart
            this.criticality_0 = this.criticality_0 - 0.8;
            this.criticality_1 = this.criticality_1 - 0.8;
            this.criticality_2 = this.criticality_2 - 0.8;
            this.criticality_3 = this.criticality_3 - 0.8;
            this.criticality_4 = this.criticality_4 - 0.8;

            // Number of elements 
            this.number_of_info_criticality = criticality_0;
            this.number_of_low_criticality = criticality_1;
            this.number_of_medium_criticality = criticality_2;
            this.number_of_high_criticality = criticality_3;
            this.number_of_critical_criticality = criticality_4;
        },
        getInProgressIncidents() {
            // Get all incidents with status.status == 'In Progress'
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].status[data[i].status.length - 1].status == 'active') {
                    data_filtered.push(data[i]);
                }
            }
            this.inProgressIncidents = data_filtered.length;
        },
        getResolvedIncidents() {
            // Get all incidents with status.status == 'Resolved'
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].status[data[i].status.length - 1].status == 'closed') {
                    data_filtered.push(data[i]);
                }
            }
            this.closedIncidents = data_filtered.length;
        },
        getNewIncidents() {
            // Get all incidents with status.status == 'New'
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].status[data[i].status.length - 1].status == 'new') {
                    data_filtered.push(data[i]);
                }
            }
            this.newIncidents = data_filtered.length;
        },
        createData() {
            //get assets from api
            $api.getAllAssets(false).then(response => {
                this.assets = response;
            }).catch(error => {
            });

            //get all events from api
            $api.getAllEvents(false).then(response => {
                this.events = response;
            }).catch(error => {
            });

            //getr all teams from api
            $api.getAllTeams(false).then(response => {
                this.teams = response;
                //get all correlation rules from api
                $api.getAllCorrelationRules(false).then(response => {
                    this.correlationRules = response;
                    this.$refs.createData.openModal();
                }).catch(error => {
                });
            }).catch(error => {
            });


        },
        changeToEditMode() {
            this.editMode = true;
            this.selectedData = null
        },
        deleteData() {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.deleteIncident(this.selectedData[i].id).then((response) => {
                            //console.log(`[+] Incident ${this.selectedData[i].name} deleted`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('recordDeleted'), life: 3000 });
                            //refresh 
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (deleteIncident): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('recordNotDeleted'), life: 3000 });
                        });
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        setCriticalityForSelectedItems(criticality){
            this.$confirm.require({
                message: this.$t('confirmDialog'),
                header: this.$t('confirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.updateIncident(this.selectedData[i].id, { "severity": criticality }).then((response) => {
                            console.log(`[+] Asset ${this.selectedData[i].id} updated`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                            //refresh
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (updateAsset): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
                        });;
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        setStatusSelectedItems(status){
            this.$confirm.require({
                message: this.$t('confirmDialog'),
                header: this.$t('confirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        let new_status = {
                            "status": status.status,
                            "description": status.description,
                        }
                        $api.updateIncident(this.selectedData[i].id, { "status": new_status }).then((response) => {
                            console.log(`[+] Incident ${this.selectedData[i].id} updated`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentUpdated'), life: 3000 });
                            //refresh
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (updateIncident): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotUpdated'), life: 3000 });
                        });;
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateStatus(id, status) {
            // Add new status to the incident
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    let new_status = {
                        "status": status.status,
                        "description": status.description,
                    }
                    data[i].status.push(new_status);
                    $api.updateIncident(id, { "status": new_status }).then((response) => {
                        console.log(`[+] Incident ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateIncident): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            this.getInProgressIncidents();
            this.getResolvedIncidents();
            this.getNewIncidents();
        },
        updateSeverity(id, severity) {
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].severity = severity;
                    $api.updateIncident(id, { "severity": severity }).then((response) => {
                        console.log(`[+] Incident ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateIncident): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            this.calcPercentageByCriticality();
        },
        updateTeam(id, team) {
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].team = team;
                    $api.updateIncident(id, { "team_id": team.id }).then((response) => {
                        console.log(`[+] Incident ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateIncident): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
        },
        createArrayOfIds(array) {
            let ids = [];
            for (let i = 0; i < array.length; i++) {
                ids.push(array[i].id);
            }
            return ids;
        },
        addNewIncidents(new_title, new_desc) {
            let new_incident = {
                "title": new_title,
                "description": new_desc,
                "severity": 1,
                "status": {
                    "status": "new",
                    "description": "New incident"
                },
                "team_id": this.new_team.id,
                "correlation_rule_id": this.new_correlationRule.id,
                "assets": this.createArrayOfIds(this.new_selectedAssets),
                "events": this.createArrayOfIds(this.new_selectedEvents),
            }
            $api.createIncident(new_incident).then((response) => {
                console.log(`[+] Incident created`);
                this.data.push(response);
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentCreated'), life: 3000 });
                this.$refs.createData.closeModal();
            }).catch((err) => {
                console.log(`[-] Error (createIncident): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotCreated'), life: 3000 });
            });
        },
        async createChartsData() {
            console.log("---------------createChartsData-----------------");
            /*
            // find most recent event based on created_at
            let most_recent_event = this.data[0];
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].created_at > most_recent_event.created_at) {
                    most_recent_event = this.data[i];
                }
            }
            */
            let n = 15

            // get the 15 days before today
            let date = new Date();
            let date_n_days_before = new Date(date.setDate(date.getDate() - n));

            // get all events from the last 7 days
            let events_last_n_days = [];
            for (let i = 0; i < this.data.length; i++) {
                let event_date = new Date(this.data[i].created_at);
                if (event_date > date_n_days_before) {
                    events_last_n_days.push(this.data[i]);
                }
            }
            console.log("EVENTS LAST N DAYS: ");
            console.log(events_last_n_days);

            // get all days between today and N days
            let dates = [];
            for (let i = 0; i < n; i++) {
                let date = new Date();
                let date_n_days_before = new Date(date.setDate(date.getDate() - i));
                dates.push(date_n_days_before.toISOString().slice(0, 10));
            }

            // sort dates from oldest to newest
            dates.sort(function(a, b) {
                let date_a = new Date(a);
                let date_b = new Date(b);
                return date_a - date_b;
            });

            console.log("DATES:", dates);

            this.stackedData = {};
            this.stackedData.labels = dates;
            this.stackedData.datasets = [];

            // create chart data
            this.stackedData.datasets.push({
                type: 'bar',
                label: this.$t('information'),
                backgroundColor: '#AEB5C1',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            });
            this.stackedData.datasets.push({
                type: 'bar',
                label: this.$t('low'),
                backgroundColor: '#6DC193',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            });
            this.stackedData.datasets.push({
                type: 'bar',
                label: this.$t('medium'),
                backgroundColor: '#F7B34C',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            });
            this.stackedData.datasets.push({
                type: 'bar',
                label: this.$t('high'),
                backgroundColor: '#EF6154',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            });
            this.stackedData.datasets.push({
                type: 'bar',
                label: this.$t('critical'),
                backgroundColor: '#B83C32',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            });


            // get number criticality per day
            for (let i = 0; i < dates.length; i++) {
                //console.log("---------------");
                //console.log("DATE:", dates[i]);
                for (let j = 0; j < events_last_n_days.length; j++) {
                    let event_date = new Date(events_last_n_days[j].created_at);
                    let date_string = event_date.toISOString().slice(0, 10);
                    //console.log("event date:", date_string);    
                    if (date_string == dates[i]) {
                        //console.log("* EVENT DATE:", date_string);    
                        switch (events_last_n_days[j].severity) {
                            case 0:
                                this.stackedData.datasets[0].data[i] += 1;
                                break;
                            case 1:
                                this.stackedData.datasets[1].data[i] += 1;
                                break;
                            case 2:
                                this.stackedData.datasets[2].data[i] += 1;
                                break;
                            case 3:
                                this.stackedData.datasets[3].data[i] += 1;
                                break;
                            case 4:
                                this.stackedData.datasets[4].data[i] += 1;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            console.log("STACKED DATA:", this.stackedData);

            /* --------------- Pie Chart 2 ----------------- */
            // loop data and get all diferent source.correlation_rule and add them to the pie chart
            // get all diferent correlation_rules
            let correlation_rules = [{
                "name": "",
                "count": 0
            }];
            for (let i = 0; i < this.data.length; i++) {
                let found = false;
                for (let j = 0; j < correlation_rules.length; j++) {
                    if (correlation_rules[j].name == this.data[i].correlation_rule.title) {
                        correlation_rules[j].count += 1;
                        found = true;
                    }
                }
                if (!found) {
                    correlation_rules.push({
                        "name": this.data[i].correlation_rule.title,
                        "count": 1
                    });
                }
            }
            console.log("CORRELATION RULES:", correlation_rules);
            // get top 5 correlation_rules
            correlation_rules.sort(function(a, b) {
                return b.count - a.count;
            });
            correlation_rules = correlation_rules.slice(0, 5);
            console.log("TOP 5 DETECTION RULES:", correlation_rules);
            // get labels and data
            let labels = [];
            let data = [];
            for (let i = 0; i < correlation_rules.length; i++) {
                labels.push(correlation_rules[i].name);
                data.push(correlation_rules[i].count);
            }
            this.pieChartData1 = {
                labels: labels,
                datasets: [{
                    data: data,
                }]
            };

            /* --------------- Top Assets ----------------- */
            // loop data and get the 7 assets that appear the most
            // get all diferent assets
            let assets = [];
            for (let i = 0; i < this.data.length; i++) {
                let found = false;
                for (let k = 0; k < this.data[i].assets.length; k++) {
                    for (let j = 0; j < assets.length; j++) {
                        if (assets[j].id == this.data[i].assets[k].id) {
                            assets[j].count += 1;
                            found = true;
                            switch (this.data[i].severity) {
                                case 0:
                                    assets[j].events[0] += 1;
                                    break;
                                case 1:
                                    assets[j].events[1] += 1;
                                    break;
                                case 2:
                                    assets[j].events[2] += 1;
                                    break;
                                case 3:
                                    assets[j].events[3] += 1;
                                    break;
                                case 4:
                                    assets[j].events[4] += 1;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    if (!found) {
                        assets.push({
                            "id": this.data[i].assets[k].id,
                            "name": this.data[i].assets[k].name,
                            "count": 1,
                            "events": [0, 0, 0, 0, 0]
                        });
                        switch (this.data[i].severity) {
                            case 0:
                                assets[assets.length - 1].events[0] += 1;
                                break;
                            case 1:
                                assets[assets.length - 1].events[1] += 1;
                                break;
                            case 2:
                                assets[assets.length - 1].events[2] += 1;
                                break;
                            case 3:
                                assets[assets.length - 1].events[3] += 1;
                                break;
                            case 4:
                                assets[assets.length - 1].events[4] += 1;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            console.log("ASSETS:", assets);
            // get top 7 assets
            assets.sort(function(a, b) {
                return b.count - a.count;
            });
            assets = assets.slice(0, 7);
            console.log("TOP 7 ASSETS:", assets);

            // get labels and data
            labels = [];
            data = [];
            for (let i = 0; i < assets.length; i++) {
                labels.push(assets[i].name);
            }


            this.barData = {
                labels: labels,
                datasets: [{
                        type: 'bar',
                        label: this.$t('info'),
                        borderColor: '#AEB5C1',
                        data: [0, 0, 0, 0, 0, 0, 0] // asset[0].events[0], asset[1].events[0], asset[2].events[0], asset[3].events[0], asset[4].events[0], asset[5].events[0], asset[6].events[0]
                    }, {
                        type: 'bar',
                        label: this.$t('low'),
                        backgroundColor: '#6DC193',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    }, {
                        type: 'bar',
                        label: this.$t('medium'),
                        backgroundColor: '#F7B34C',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        type: 'bar',
                        label: this.$t('high'),
                        backgroundColor: '#EF6154',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        type: 'bar',
                        label: this.$t('critical'),
                        backgroundColor: '#B83C32',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    }
                ]
            };

            // fill data
            for (let i = 0; i < assets.length; i++) {
                this.barData.datasets[0].data[i] = assets[i].events[0];
                this.barData.datasets[1].data[i] = assets[i].events[1];
                this.barData.datasets[2].data[i] = assets[i].events[2];
                this.barData.datasets[3].data[i] = assets[i].events[3];
                this.barData.datasets[4].data[i] = assets[i].events[4];
            }

            console.log("BAR DATA:", this.barData);
        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // set actions permissions
        this.actions[0].permit = this.hasUserPermissionGlobal(this.user, 'incident:create')
        this.actions[1].permit = this.hasUserPermissionGlobal(this.user, 'incident:update')
        this.actions[2].permit = this.hasUserPermissionGlobal(this.user, 'incident:create')
            // Loop actions, if all are false, then hide actions button
        let allFalse = true;
        for (let i = 0; i < this.actions.length; i++) {
            if (this.actions[i].permit) {
                allFalse = false;
                break;
            }
        }
        if (allFalse) {
            this.showActions = false;
        }

        // get incidents from API
        if (process.env.VUE_APP_ENV === 'development') {
            await (this.data = require('./incidents.json'));
            await (this.data_backup = require('./incidents.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllIncidents().then((response) => {
                this.data = response;
                this.data_backup = response;
                console.log("INCIDENTS: ", this.data);

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                this.setSelectedData(this.data[0]);
                if (this.data)
                    this.details = this.data[0];
                this.loading_table = false;

                // ***************** create charts data *****************
                this.createChartsData();

                // Calculate in progress incidents
                this.getInProgressIncidents();
                // Calculate resolved incidents
                this.getResolvedIncidents();
                // Calculate new incidents
                this.getNewIncidents();

                //this.loadGroups();
                this.calcPercentageByCriticality();
                this.lastHours = this.hours[0];

            }).catch((err) => {
                console.log(`[-] Error (getAllIncidents): ${err}`);
                this.loading_table = false;
                this.loading_details = false;
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

        // get teams from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.teams = require('./teams.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            $api.getAllTeams(false).then((response) => {
                this.teams = response;
                console.log("TEAMS: ", this.teams);
            }).catch((err) => {
                console.log(`[-] Error (getAllTeams): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

    }
};
export default MyShared;