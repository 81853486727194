import { useUserStore } from '../../../store/store.js'
var MyShared = {
    /* Name */
    name: "NameHere",
    inject: ["mq"],
    /* Data */
    data() {
        return {
            toggleNav: false,
            user: {
                is_superuser: false,
                permissions: [],
            },
            is_side_open: false,
        };
    },
    /* Components */
    components: {},
    /* Props */
    props: ['target'],
    /* Methods */
    methods: {
        setCurrentRoute() { },
        toggleSidebar() {
            this.toggleNav = !this.toggleNav;
            document.getElementsByClassName('main-menu')[0].style.width = this.toggleNav ? '250px' : '55px';
        },
        hasUserPermission(permission) {
            if (this.user.is_superuser) {
                return true;
            }

            // Remove / from permission
            permission = permission.replace("/", "");
            // Replace all - with _
            permission = permission.replace(/-/g, "_");
            //console.log("-------------> PERMISSION:", permission);

            // If path is manage-roles
            if (permission == "manage_roles:get") {
                for (let i = 0; i < this.user.permissions.length; i++) {
                    if (this.user.permissions[i].value == "user:get" || this.user.permissions[i].value == "role:get" || this.user.permissions[i].value == "team:get") {
                        return true;
                    }
                }
            }

            // Loop through user.permissions
            // Check if user.permissions[i].value is equal to permission
            for (let i = 0; i < this.user.permissions.length; i++) {
                if (this.user.permissions[i].value === permission) {
                    return true;
                }
            }
            // If it is not, return false
            return false;
        },
        addPadding() {
            //console.log("add padding");
            if (this.is_side_open) 
                return;
            //find maincontainer
            var mainContainer = document.getElementsByClassName('maincontainer')[0];
            // find sitebar
            var siteBar = document.getElementsByClassName('siterow-desktop')[0];
            //add padding to maincontainer
            mainContainer.style.paddingLeft = "280px";
            siteBar.style.paddingLeft = "280px";
            this.is_side_open = true;
        },
        reducePadding() {
            //console.log("reduce padding");
            if (!this.is_side_open) 
                return;
            var mainContainer = document.getElementsByClassName('maincontainer')[0];
            // find sitebar
            var siteBar = document.getElementsByClassName('siterow-desktop')[0];
            //reduce padding to maincontainer
            mainContainer.style.paddingLeft = "80px";
            siteBar.style.paddingLeft = "84px";
            this.is_side_open = false;
        }
    },
    async mounted() {
        // Get user object
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        //console.log("-------------> USER:", userObject);
        this.user = userObject;
        this.user.permissions = userObject.permissions;
    },
    /* Emits */
    emits: ["emits", "emits"],
};
export default MyShared;