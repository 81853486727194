import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { nextTick } from "vue";
import Timeline from "../../components/Partials/Timeline/Timeline.vue"
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import Chart from 'primevue/chart';
import { ref } from "vue";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'
import mitre from "@/assets/mitre.json";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const separatorExp = ref(/,| /);
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            status: '',
            status_has_changed: false,
            close: true,
            locked: true,
            details: {
                "title": "Modification of ld.so.preload",
                "description": "Identifies modification of ld.so.preload for shared object injection. This technique is used by attackers to load arbitrary code into processes.",
                "severity": 3,
                "created_at": "2022-11-22T02:34:37.641754+00:00",
                "status": [{
                    "status": "Created",
                    "description": "New event detected",
                    "user": "Detection System"
                }],
                "asset": {
                    "hostname": "centos7-yipi",
                    "name": "centos7-yipi",
                    "description": "CentOS 7",
                },

                "id": "74bff7e4-de1d-49cc-ab7b-11c184420812",
                "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9",
                "incidents": [{
                    "created_at": "2022-11-25T04:47:20.760329+00:00",
                    "status": [{
                        "status": "Created",
                        "description": "New event detected",
                        "user": "Correlation System"
                    }],
                    "description": "This rule detects rare scheduled task creations. Typically software gets installed on multiple systems and not only on a few. The aggregation and count function selects tasks with rare names.",
                    "id": "436582ee-b5de-4b49-8956-912bc9335c54",
                    "team_id": "d1a0f1aa-c548-403a-b1db-ebb701605c14",
                    "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9",
                    "title": "Rare Scheduled Task Creations",
                    "severity": 1,
                    "correlation_rule_id": "087b48f2-1948-447c-8218-744846aa2bc7",
                    "correlation_rule": {
                        "title": ''
                    }
                }],
                "logs": [{
                    "id": "01d2f1a6-a544-4557-bc76-acae3cfc5445",
                    "asset_id": "b5905c12-360e-4697-983f-700fecb2d0a9",
                    "log": {
                        "asset": {
                            "hostname": "centos7-yipi",
                            "ip": [
                                "192.168.184.137",
                                "fe80::a018:9c41:a3be:f726",
                                "192.168.122.1",
                                "172.17.0.1"
                            ],
                            "mac": [
                                "00:0c:29:db:69:85",
                                "02:42:9d:9b:d9:fc",
                                "52:54:00:f5:a2:46"
                            ],
                            "os": "CentOS Linux",
                            "id": "4cdd6fe72a244ab98846069b1f809e9f",
                            "containerized": false
                        },
                        "source": "auditd_auditbeat",
                        "msg": {
                            "action": "executed",
                            "result": "success",
                            "object": "/usr/bin/ls",
                            "uname": "yipi",
                            "uid": 1000,
                            "pid": 24267,
                            "executable": "/usr/bin/ls",
                            "args": [
                                "ls",
                                "-l"
                            ],
                            "working_directory": "/home/yipi/Documentos/python3"
                        },
                        "date": 1664177451000,
                        "date_intake": 1664177451000
                    },
                    "date": "2022-10-14T14:35:46",
                    "source_id": "4107dd1d-771f-426a-8f5c-68d756c9bf68",
                    "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9"
                }],
                "detection_rule": {
                    "configuration": {
                        "selection": {
                            "type": "PATH",
                            "name": "/etc/ld.so.preload"
                        },
                        "condition": "selection"
                    },
                    "description": "Identifies modification of ld.so.preload for shared object injection. This technique is used by attackers to load arbitrary code into processes.",
                    "severity": 3,
                    "id": "e203463b-d863-4224-b878-3694ea6f04e6",
                    "created_at": "2022-11-25T09:37:16.821052+00:00",
                    "title": "Modification of ld.so.preload",
                    "author": "E.M. Anhaus (originally from Atomic Blue Detections, Tony Lambert), oscd.community",
                    "is_active": true,
                    "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9"
                },
                "source": {
                    "id": "2230c02a-f286-4be8-88a9-bdd47ad9466f",
                    "name": "CrowdStrike Falcon"
                }
            },
            events: [{
                "title": "Console login failure",
                "description": "User louis@evil-corp.com failed first factor login to Admin Console",
                "severity": 0,
                "detection_rule": {
                    "id": "e85f1784-bf05-4142-9a41-9b731f53d85a",
                    "title": "Console login failure"
                },
                "created_at": "2022-11-09T11:39:01",
                "status": [{
                    "status": "Created",
                    "description": "New event detected.",
                    "user": "4ea905db-d246-4a33-9910-c6cc1d139bb9",
                    "date": "2022-11-09T11:39:01"
                }],
                "id": "4ea905db-d246-4a33-9910-c6cc1d139bb9",
                "organization_id": "9b3a8bb9-a643-4edf-b796-bf630a8cd833"
            }],
            incidents: [{
                "title": "Evil Office 1 - DDOS",
                "description": "A DDoS attack has been detected on three assets of Evil Office 1 at North Carolina",
                "severity": 1,
                "created_at": "2022-10-07 09:55:14",
                "status": [{
                    "status": "Working",
                    "description": "I've been investigating those strange logs. Nate, pls check this: https://nsfocusglobal.com/freakout-malware-analysis-freakout-samples/"
                }],
                "id": "4ea905db-d246-4a33-9910-c6cc1d139bb9",
                "team_id": "9b3a8bb9-a643-4edf-b796-bf630a8cd833",
                "correlation_rule": {
                    "id": "8944febb-2162-4d9d-b3f0-9fc89e16d3d9",
                    "title": "DDOS Attack"
                },
                "events": [
                    "4ea905db-d246-4a33-9910-c6cc1d139bb9"
                ]
            }],
            loading_details: false,
            // Preguntar que tipos hay
            types: [
                { label: 'Server', value: 'Server' },
                { label: 'Router', value: 'Router' },
                { label: 'Switch', value: 'Switch' },
                { label: 'Firewall', value: 'Firewall' },
                { label: 'Workstation', value: 'Workstation' },
                { label: 'Printer', value: 'Printer' },
                { label: 'Other', value: 'Other' }
            ],
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
            statuses: [
                { label: this.$t('new'), status: 'new', default_description: 'New Event detected' },
                { label: this.$t('active'), status: 'active', default_description: 'Event is being investigated' },
                { label: this.$t('mitigated'), status: 'mitigated', default_description: 'Event has been mitigated' },
                //{ label: this.$t('partiallyMitigated'), status: 'partially_mitigated', default_description: 'Event has been partially mitigated' },
                { label: this.$t('closed'), status: 'closed', default_description: 'Event has been closed' },
            ],
            separatorExp: (/,| /),
            status_timeline: [],
            full_mitre_matrix: [],
            mitre_available: false,
            mitre_matrix: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart,
        Timeline
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        lockForm() {
            this.locked = true;
            let dropdowns = document.getElementsByClassName("dropdown-menu");
            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove("show");
            }
            /*
            let toggles = document.getElementsByClassName("dropdown-toggle")
            for (let i = 0; i < toggles.length; i++) {
                toggles[i].setAttribute("aria-expanded", 'false')
            }
            */
        },
        unlockForm() {
            this.locked = false;
        },
        onCancelUpdate() {
            // Reset form
            this.details.description = this.description;
            this.details.severity = this.severity;
            this.details.status = this.status_backup
            this.details.status[this.details.status.length - 1] = this.status
            this.lockForm();
        },
        onSaveUpdate() {
            let to_update_object = {}
            if (this.details.description != this.description) {
                to_update_object.description = this.details.description
            }
            if (this.details.severity != this.severity) {
                to_update_object.severity = this.details.severity
            }
            if (this.status_has_changed) {
                to_update_object.status = this.details.status[this.details.status.length - 1]
            }

            // Update asset via API and update local data
            $api.updateEvent(this.$route.query.id, to_update_object).then((response) => {
                //console.log(`[+] Updated event: ${response.data}`);
                this.severity = this.details.severity;
                //this.status = this.details.status[this.details.status.length-1].status
                this.criticality = this.details.criticality;
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('eventUpdated'), life: 3000 });
                this.lockForm();
                //refresh page
                //this.$router.go();
            }).catch((err) => {
                console.log(`[-] Error (updateEvent): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('eventNotUpdated'), life: 3000 });
            });
        },
        addNewStatus(status) {
            this.details.status.push({
                "status": status.status,
                "description": status.default_description,
            });
            //console.log("NEW STATUS: ", this.details.status[this.details.status.length - 1]);

            this.status_has_changed = true;
        },
        generateStatusTimelineData() {
            // format { dateLabel: 'January 2017', title: 'Gathering Information' }
            // get all status
            for (let i = 0; i < this.details.status.length; i++) {
                let date = new Date(this.details.status[i].date);
                let dateLabel = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                let title = this.details.status[i].status;
                let id = 0
                let severity = title
                this.status_timeline.push({ dateLabel, title, id, severity });
            }
            // sort status by date
            this.status_timeline.sort((a, b) => {
                return new Date(a.dateLabel) - new Date(b.dateLabel);
            });
        },
        getMitreMatrix() {
            if (this.details.mitre.length > 0) {
                this.mitre_available = true
                this.mitre_matrix = this.details.mitre
                // Loop this.mitre_matrix and add open 
                for (let i = 0; i < this.mitre_matrix.length; i++) {
                    this.mitre_matrix[i].open = false
                }
            } else {
                this.mitre_available = false
            }
        },
        openRoute(url){
            // Open url on new window
            window.open(url,'_blank');
        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;
        // get sources from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.data = require('./events.json'));
            //await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_details = true;

            $api.getOneEvent(this.$route.query.id, true).then((response) => {
                this.details = response;
                this.description = this.details.description;
                this.status = this.details.status[this.details.status.length - 1]
                this.status_backup = this.details.status
                this.severity = this.details.severity;
                this.loading_details = false;

                this.getMitreMatrix();
                this.generateStatusTimelineData();
            }).catch((err) => {
                console.log(`[-] Error (getOneEvent): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    }
};
export default MyShared;