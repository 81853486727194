import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Sign In",
    /* Data */
    data() {
        return {
            user: {
                "permissions": []
            },
            username: "",
            password: "",
        };
    },
    /* Components */
    components: {},
    /* Props */
    props: {},
    /* Methods */
    methods: {
        login(username, password) {
            $api.login(
                username,
                password
            ).then(
                response => {
                    this.$cookies.set("ACCESS_TOKEN", response.access_token);
                    this.$router.push({ name: "Home" });
                }
            ).catch(
                err => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('Login error'),
                        detail: this.$t('Wrong username or password'),
                        life: 3000
                    });
                    console.log(`[-] Error (login): ${err}`);
                }
            )
        }
    },
};
export default MyShared;